import React, { useState } from "react";
import { arrayMoveImmutable } from "array-move";
import {
  Box,
  Table,
} from "@mui/material";
import "../../../../../../../src/components/Callincoming/index.css"
import ContentBlock from "components/ContentBlock";

// const SortableItem = sortableElement(
//   ({ service, number, deleteService, withActions }) => {
//     console.log('sssssss '+service);
//     return (
//       <BillingTableRow
//         service={service}
//         index={number}
//         onDelete={deleteService}
//         withActions={withActions}
//       />
//     );
//   }
// );

// const SortableList = sortableContainer(
//   ({
//     services,
//     deleteService,
//     discardNewService,
//     saveNewService,
//     withActions,
//     showAddService
//   }) => {
//     return (
//       <TableBody>
//         {services.map((service, index) => {
//           return Object.keys(service).length > 0 && (
//             <SortableItem
//               key={`service-item-${index}`}
//               index={index}
//               service={service}
//               number={index}
//               deleteService={deleteService}
//               withActions={withActions}
//             />);
//         })}
//         {showAddService && (
//           <NewBillingRow
//             discardNewService={discardNewService}
//             saveNewService={saveNewService}
//           />
//         )}
//       </TableBody>
//     );
//   }
// );

export default function BillingTableComponent({
  services = [],
  onChange,
  withActions,
  deleteService,
  deleteServices,
  saveNewService,
  error
}) {

  const [showAddService, setShowAddService] = useState(false);
  const onSortEnd = ({ oldIndex, newIndex }) => {
    onChange && onChange(arrayMoveImmutable(services, oldIndex, newIndex))
  };

  const handleSaveNewService = (data) => {
    saveNewService(data);
    setShowAddService(false);
  };

  return (
    <Box>
      {/* <Box>
        <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Grid item xs={4}>
            <Box>
              <img src="http://localhost:3000/images/hoc-logo.png" width={296} height={188} alt="logo" />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className="logoRIghtText">
              <p className="m-0">7525 SE 24th street, suite 408</p>
              <p className="m-0">po box 1760</p>
              <p className="m-0">Mercer Island, Wa 98040</p>
              <p className="m-0">206-622-3500</p>
            </Box>
          </Grid>

        </Grid>
        <Grid container spacing={2} className="mb-4">
          <Grid item xs={8}>
            <Box className="shipDetails">
              <p className="m-0">Acct#: <span>8014</span></p>
              <p className="m-0">James Hardisty</p>
              <p className="m-0">David Hardisty</p>
              <p className="m-0">5036 84th Ave SE</p>
              <p className="m-0">Mercer Island, WA 98040-4618</p>
            </Box>
            <p className="mt-2 mb-3">Please return this part of statement note any Changes in address or phone number.</p>
          </Grid>
          <Grid item xs={4}>
            <h4 style={{ textAlign: "center" }}>04/04/2024</h4>
            <Box className="logoRIghtText" sx={{ border: "1px solid #000" }}>
              <label className="bgDark-custm">BALANCE DUE</label>
              <span style={{ fontWeight: 600, padding: "6px", textAlign: "center", display: "block" }}>$645.19</span>
            </Box>
            <Box className="logoRIghtText" sx={{ border: "1px solid #000" }}>
              <label className="bgDark-custm">AMOUNT ENCLOSED</label>
              <span style={{ fontWeight: 600, padding: "6px", display: "block" }}>$</span>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small" aria-label="simple table" sx={{border: "1px solid #000"}}>
                <TableHead >
                  <TableRow>
                    <TableCell sx={{background: "#000", color: "#fff"}}>DATE</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}>DESCRIPTION</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}>CHARGES</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}>CREDITS</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}>BALANCE</TableCell>
                    <TableCell sx={{background: "#000", color: "#fff"}}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>04/04/2024</TableCell>
                    <TableCell>454710</TableCell>
                    <TableCell>165.15</TableCell>
                    <TableCell>0.00</TableCell>
                    <TableCell sx={{whiteSpace: "nowrap"}}>165.15 Tree/Yard Spray</TableCell>
                    <TableCell rowSpan={10} sx={{verticalAlign: "middle", textAlign: "center"}}> Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>04/04/2024</TableCell>
                    <TableCell>454710</TableCell>
                    <TableCell>165.15</TableCell>
                    <TableCell>0.00</TableCell>
                    <TableCell sx={{whiteSpace: "nowrap"}}>165.15 Tree/Yard Spray</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>04/04/2024</TableCell>
                    <TableCell>454710</TableCell>
                    <TableCell>165.15</TableCell>
                    <TableCell>0.00</TableCell>
                    <TableCell sx={{whiteSpace: "nowrap"}}>165.15 Tree/Yard Spray</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{marginTop: "20px",textAlign:"center"}}>Please see reverse side for important information</Box>
            <Box sx={{textAlign: "center"}}><img src="http://localhost:3000/images/hoc-logo.png" width={296} height={188} alt="logo" /> <span style={{marginLeft: "10px"}}>PO BOX 1760, MERCER ISLAND, WA 98040 206-622-3500</span></Box>
          </Grid>
        </Grid>
      </Box> */}
      <ContentBlock>
        <Table>
          <tr>
            <td>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    <img src="http://localhost:3000/images/hoc-logo.png" alt="" />
                  </td>
                  <td style={{ paddingLeft: "23px" }}>
                    <table>
                      <tr>
                        <td style={{ fontWeight: "600" }}>
                          7525 se 20th street, suite 408
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600" }}>7525 se 20th</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600" }}>7525 se 20th street, suite</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600" }}>7525 se 20th</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr >
            <td style={{ padding: "0px ", marginBottom: "80px" }}>
              <table style={{ width: "100%", marginBottom: "5px" }}>
                <tr style={{}}>
                  <td style={{ width: "70%" }}>
                    <table style={{ width: "100%", marginTop: "10px" }}>
                      <tr>
                        <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600" }}>Acct #: 8014</td>
                      </tr>
                      <tr style={{ display: "block", marginTop: "20px" }}>
                        <td>
                          Please return this part of statment and note any change in
                          address or phone number.
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ width: "30%" }}>
                    <table style={{ verticalAlign: "baseline", width: "100%" }}>
                      <tr>
                        <td style={{ fontWeight: "600", fontSize: "16px", textAlign: "center" }} >
                          4/4/2024
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table style={{ width: "100%", border: "1px solid" }}>
                            <tr>
                              <th style={{ background: "rgb(17 96 137)", color: "#fff", textAlign: "center", padding: "4px 10px" }}>
                                BALANCE DUE
                              </th>
                            </tr>
                            <tr>
                              <td style={{ height: "20px", fontWeight: "600", textAlign: "center", fontSize: "20px", padding: "7px" }} >
                                $654.19
                              </td>
                            </tr>
                            <tr>
                              <th style={{ background: "rgb(17 96 137)", color: "#fff", textAlign: "center", padding: "4px 10px" }}> AMOUNT ENCLOSED</th>
                            </tr>
                            <tr>
                              <td style={{ height: "20px", fontWeight: "600", textAlign: "center", fontSize: "20px", padding: "7px" }} >
                                $_______________
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
            <tr>
              <td style={{ textAlign: "end" }}>
                <table style={{ width: "100%" }}>
                  <tr>
                    {/* <td style={{   marginBottom: "10px" }}><h3>{invView?.hoc_inv_num}</h3></td> */}
                  </tr>
                  <tr >
                    <td  >
                      <table className='invoices_table'>
                        <tr>
                          <td className='invoices_td' >INVOICE NO : </td>
                          <td className='invoices_td' >TRACKING NO : </td>
                          <td className='invoices_td' >CLUB NO.</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table className='serivecs'>
                        <tr>
                          <td className='serivecs_td' >
                            <table>
                              <tr><th>SERIVCE PERFORMANCE FOR:</th></tr>
                              <tr>
                                <td>
                                  <span>MEMBER: </span>
                                  <span>NA</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>STREET: </span>
                                  <span>NA</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>CITY: </span>
                                  <span>NA</span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <span>DATE: </span>
                                  <span>NA</span>
                                </td>
                              </tr>
                            </table>
                          </td>
                          <td style={{ width: "50%", textAlign: "left" }}>
                            <table>
                              <tr>
                                <th>SERIVCE PERFORMANCE FOR: </th>
                                <td>NA</td>
                              </tr>
                              <tr>
                                <td><span>NA</span></td>
                              </tr>
                              <tr>
                                <td> <span>NA</span> </td>
                              </tr>
                              <tr>
                                <td><span>NA</span> </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          <tr>
            <td>
              <table className='tableRow' >
                <tr style={{ backgroundColor: "#116089", color: "#fff" }}>
                  <th>MATERIAL</th>
                  <th>AMOUNT</th>
                  <th>DESCRIPTION OF WORK / LABOR</th>
                  <th>AMOUNT</th>
                </tr>
                <tr>
                  <td> NA</td>
                  <td> NA</td>
                  <td> NA </td>
                  <td>NA</td>
                </tr>
                <tr>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                </tr>
                <tr>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                </tr>
                <tr>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                  <td>NA</td>
                </tr>

              </table>
            </td>
          </tr>
          <table style={{ width: "100%" }}>
            <tr>
              <th className='remark' >REMARK: </th>
              <td style={{ width: "25%" }}>
                <table style={{ marginRight: "15px" }}>
                  <tr>
                    <td>
                      <table className='arrived_table' >
                        <tr>
                          <th>ARRIVED</th>
                          <th>DEPARTED</th>
                          <th>TOTAL TIME</th>
                        </tr>
                        <tr>
                          <td>NA</td>
                          <td>NA</td>
                          <td>NA</td>

                        </tr>
                      </table>

                    </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="hoc-logo.png" alt="" />
                    </td>
                  </tr>
                </table>
              </td>
              <td className='amountTable'>
                <table >
                  <tr>
                    <th >TOTAL LABOR</th>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <th > TOTAL MATRERIAL</th>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <th > NATE SALE </th>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <th > Rate 10.35% TAX</th>
                    <td>NA</td>
                  </tr>
                  <tr>
                    <th className='total-amt' ><span style={{ color: "white", fontSize: "17px", fontWeight: "500" }}>TOTAL AMOUNT $</span>  </th>
                    <td className='total-amt'><span style={{ color: "white", fontSize: "17px", fontWeight: "500" }}>NA</span></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </Table>

        <Table >
          <tr>
            <td>
              <table style={{ width: " 100%", margin: "2rem 0rem" }}>
                <tr>
                  <td><img src="http://localhost:3000/images/hoc-logo.png" alt="" /></td>
                  <td>
                    <table>
                      <tr>
                        <td>Home Comfort Allince</td>
                      </tr>
                      <tr>
                        <td>9680 153rd Ave NE</td>
                      </tr>
                      <tr>
                        <td>Redmond, WA 98052</td>
                      </tr>
                      <tr>
                        <td>(425)881-7920</td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table>
                      <tr>
                        <td style={{ fontWeight: "600", padding: "4px 10px" }}>INVOICE : </td>
                        <td>179104887</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600", padding: "4px 10px" }}>INVOICE DATE : </td>
                        <td>12/19/2023</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600", padding: "4px 10px" }}> COMPLETED DATE : </td>
                        <td>12/19/2023</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "600", padding: "4px 10px" }}>CUSTOMER PO : </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    <table>
                      <tr>
                        <td style={{ fontWeight: "600" }}>Billing Address</td>
                      </tr>
                      <tr>
                        <td>HARDISTY, JAMES 30062</td>
                      </tr>
                      <tr>
                        <td>5036 84th Avenue Southeast</td>
                      </tr>
                      <tr>
                        <td>Mercer Island, WA 980404 USA</td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <table style={{ width: "100%", textAlign: "center" }}>
                      <tr>
                        <td style={{ fontWeight: "600" }}>Billing Address</td>
                      </tr>
                      <tr>
                        <td>HARDISTY, JAMES 30062</td>
                      </tr>
                      <tr>
                        <td>5036 84th Avenue Southeast</td>
                      </tr>
                      <tr>
                        <td>Mercer Island, WA 980404 USA</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td
                    colspan="2"
                    style={{ textAlign: "center", fontSize: "18px", fontWeight: "600" }}
                  >
                    Description of work
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table style={{ borderTop: "2px , solid" }} >
                <tr>
                  <td>
                    <p style={{ fontWeight: "600" }}>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quidem eaque perspiciatis architecto saepe explicabo porro
                      maiores optio? Dignissimos sapiente, illo ratione itaque enim
                      labore dolores est, distinctio nemo, aperiam porro? Lorem
                      ipsum dolor sit amet consectetur adipisicing elit. Quidem
                      eaque perspiciatis architecto saepe ratione itaque enim labore
                      dolores est, distinctio nemo, aperiam porro?
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table style={{ width: "100%", textAlign: "left", borderTop: " 1px , solid" }}>
                      <tr>
                        <th>Task #</th>
                        <th style={{ padding: "0px ,21px" }}>Description</th>
                        <th>Quantity</th>
                      </tr>
                      <tr>
                        <td>T885511</td>
                        <td>
                          <p style={{ padding: "5px, 21px", margin: "10px" }}>
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Deserunt exercitationem quasi ferendis, molestias
                            in fugiat asperiores optio dolores!
                          </p>
                        </td>
                        <td>1.00</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <p style={{ padding: "5px, 21px", margin: "10px" }}>
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Deserunt exercitationem quasi ferendis, molestias
                            in fugiat asperiores optio dolores!
                          </p>
                        </td>
                        <td>1.00</td>
                      </tr>
                      <tr>
                        <td>T45245</td>
                        <td>
                          <p style={{ padding: "5px, 21px", margin: "10px" }}>
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit. Deserunt exercitationem quasi ferendis, molestias
                            in fugiat asperiores optio dolores!
                          </p>
                        </td>
                        <td>1.00</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table style={{ width: "100%", borderTop: "1px solid " }}>
                <tr>
                  <td style={{ width: "100%" }}>
                    <table style={{ float: "right" }}>
                      <tr>
                        <td style={{ fontWeight: " 600" }}>Potential  Saving</td>
                        <td>$0.00</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: " 600" }}>Sub-Total</td>
                        <td>$0.00</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: " 600" }}>Tax</td>
                        <td>$0.00</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: " 600" }}>Total Due</td>
                        <td>$0.00</td>
                      </tr>
                      <tr style={{ marginTop: "5px" }}>
                        <td style={{ fontWeight: " 600" }}>Balance Due</td>
                        <td>$0.00</td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "center" }}>Thanku you for choosing home comfort allince</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table>
                <tr>
                  <td>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo hic fuga, laudantium porro corporis quaerat expedita, laboriosam consequatur, a illo doloribus quos eaque. Ut nisi quia error, sequi sed eveniet!
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Qui sequi distinctio deleniti dignissimos maxime rerum sunt obcaecati! Nihil necessitatibus odit ducimus suscipit quae nisi, assumenda quaerat laboriosam similique, iste eum!
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </Table>


        {/* <table
          style={{ width: '740px', borderCollapse: 'collapse', margin: 'auto', fontFamily: 'sans-serif', border: '1px solid #ddd' }}
          cellpadding="0" cellspacing="0">
          <tr>
            <td style={{ padding: '0px 10px' }}>
              <table style={{ width: '50%' }}>
                <tr>
                  <td>
                    <h2 style={{ fontSize: '18px', fontWeight: '600', color: 'black', marginTop: "15px" }}>
                      HOME OWNERS CLUB
                    </h2>
                  </td>
                  <td >
                    <span style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}>
                      Job:
                    </span>
                    <span style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}>
                      640338
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333', marginLeft: "100%" }}>
                      Dispatched:
                    </span>
                  </td>
                 
                </tr>
                <tr>                 
                  <td>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333', marginLeft: "20%" }}>
                      Member:
                    </span>
                  </td>
                </tr>

              </table>
            </td>
          </tr>
          <tr>
            <td style={{ padding: '10px 20px' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tr>
                  <td style={{ width: '50%', textAlign: 'start' }}>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      Date:
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      02/22/2023
                    </span>
                  </td>
                  <td style={{ width: '50%', textAlign: 'right' }}>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      Home:
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      (206) 622-3500
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'start' }}>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      Time:
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      02/22/2023
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'start' }}>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      Staff:
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      John Doe
                    </span>
                  </td>

                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ padding: '15px 10px 0px' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left' }}>
                    <strong>Job Contact:</strong> Home Owners Club
                  </td>

                  <td style={{ width: '50%', textAlign: 'right' }}>
                    <strong>Best Contact Phone:</strong> (206) 622-3500
                  </td>
                </tr>
                <tr>
                  <td style={{ width: '50%', textAlign: 'left', padding: '15px 0px' }} colspan="2">
                    <strong>Job Address:</strong> 7525 SE 24th St Ste 408 Mercer Island 98040-2334
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ padding: '0px 10px' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tr style={{ color: 'black' }}>
                  <td style={{ padding: '10px 5px' }}>
                    <span style={{ fontSize: '15px', fontWeight: '600', }}>
                      Member Information
                    </span>
                  </td>
                  <td style={{ padding: '10px 5px' }}>
                    <span style={{ fontSize: '15px', fontWeight: '600', }}>
                      Work
                    </span>
                  </td>
                  <td style={{ padding: '10px 5px' }}>
                    <span style={{ fontSize: '15px', fontWeight: '600', }}>
                      Email
                    </span>
                  </td>
                  <td style={{ padding: '10px 5px' }}>
                    <span style={{ fontSize: '15px', fontWeight: '600', }}>
                      Other
                    </span>
                  </td>
                </tr>
                <tr style={{ color: 'black' }}>
                  <td style={{ padding: '10px 5px' }}>
                    <span style={{ fontSize: '15px', }}>
                      Home Owners Club, Trimble, Danie
                    </span>
                  </td>
                  <td style={{ padding: '10px 5px' }}>
                    <span style={{ fontSize: '15px', }}>
                      (206) 622-3500
                    </span>
                  </td>
                  <td style={{ padding: '10px 5px' }}>
                    <span style={{ fontSize: '15px', }}>
                    </span>
                  </td>
                  <td style={{ padding: '10px 5px' }}>
                    <span style={{ fontSize: '15px', }}>
                    </span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ padding: '10px 20px' }} md={6} sm={12} xs={12} >
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <tr>
                  <td style={{ width: '50%', textAlign: 'start' }}>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      Mailing Address :
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      PO Box 1760, Mercer Island, WA 98040-1760
                    </span>
                  </td>
                  <td style={{ width: '50%', textAlign: 'right' }}>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      Email:
                    </span>
                    <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                      staff@homeownerclub.com
                    </span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <td style={{ padding: '10px 20px' }} md={3} sm={12} xs={12}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <tr>
                <td style={{ width: '50%', textAlign: 'right' }}>
                  <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>
                    Email 2:
                  </span>
                  <span style={{ fontSize: '14px', fontWeight: '600', color: '#333' }}>job@homeownerclub.com
                  </span>
                </td>
              </tr>
            </table>
          </td>
          <tr>
            <td style={{ padding: '20px 10px 0px' }}>
              <table style={{ width: '100%' }}>
                <tr>
                  <td style={{ padding: '20px 0px 0px', borderTop: '1px solid #ddd' }}>
                    <span style={{ fontSize: '15px', fontWeight: '600', color: '#000' }}>
                      Service Required:
                    </span>
                    <span style={{ fontSize: '15px', fontWeight: '600', color: '#000' }}>
                      Roofing Repair
                    </span>
                  </td>
                </tr>
                <tr>
                  <td >
                    <span style={{ fontSize: '15px', fontWeight: '600', color: '#000', marginLeft: "15px" }}>
                      Estimate to repair a torchdown roof.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td style={{ padding: '20px 0px 0px' }}>
                    <table style={{ width: '100%', borderCollapse: 'collapse', color: 'black' }} cellspacing="0" cellpadding="0">
                      <tr>
                        <td style={{ padding: '10px 5px' }}>
                          <span style={{ fontSize: '15px', fontWeight: '600' }}>
                            Dispatch To
                          </span>
                        </td>
                        <td style={{ padding: '10px 5px' }}>
                          <span style={{ fontSize: '15px', fontWeight: '600' }}>
                            Phone
                          </span>
                        </td>
                        <td style={{ padding: '10px 5px' }}>
                          <span style={{ fontSize: '15px', fontWeight: '600' }}>
                            Date Dispatched
                          </span>
                        </td>
                        <td style={{ padding: '10px 5px' }}>
                          <span style={{ fontSize: '15px', fontWeight: '600' }}>
                            Method
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ padding: '10px 5px' }}>
                          <span style={{ fontSize: '15px', color: '#000' }}>
                            HOC SPECIAL SERVICES
                          </span>
                        </td>
                        <td style={{ padding: '10px 5px' }}>
                          <span style={{ fontSize: '15px', color: '#000' }}>
                            (206) 622-3500
                          </span>
                        </td>
                        <td style={{ padding: '10px 5px' }}>
                          <span style={{ fontSize: '15px', color: '#000' }}>
                            05/22/2024
                          </span>
                        </td>
                        <td style={{ padding: '10px 5px' }}>
                          <span style={{ fontSize: '15px', color: '#000' }}>
                            Telephone
                          </span>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table> */}

      </ContentBlock>


      {/* <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              {withActions && <TableCell></TableCell>}
              <TableCell>#</TableCell>
              <TableCell>PRODUCT/SERVICE</TableCell>
              <TableCell>DESCRIPTION</TableCell>
              <TableCell>QTY</TableCell>
              <TableCell>AMOUNT</TableCell>
              {withActions && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <SortableList
            onSortEnd={onSortEnd}
            useDragHandle
            services={services}
            showAddService={showAddService}
            discardNewService={() => setShowAddService(false)}
            saveNewService={handleSaveNewService}
            deleteService={deleteService}
            withActions={withActions}
          />
        </Table>
        {error && (
          <FormHelperText error>{error}</FormHelperText>
        )}
      </TableContainer> */}
      {/* {withActions && (
        <Box mt={2}>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => setShowAddService(true)}
            disabled={showAddService}
          >
            Add Line
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            sx={{ mr: 1 }}
            onClick={() => deleteServices()}
            disabled={!services.length}
          >
            Clear all Lines
          </Button>
        </Box>
      )} */}
    </Box>
  );
}

BillingTableComponent.defaultProps = {
  widthActions: false,
  deleteService: () => { },
  deleteServices: () => { },
  discardNewService: () => { },
  saveNewService: () => { },
  error: '',
};
