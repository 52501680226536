import { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import Collapse from "components/Collapse";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import useApplication from "hooks/useApplication";
import ContentBlock from "components/ContentBlock";
import Notes from "components/Notes";
import { getContractor, getContractorsInvRequest, updateContractor } from "api/contractors";
import ContractorsForm from "../components/Form";
import ".././../../../utils/sweetalert.css"
import useAccessControl from "hooks/useAccessControl";
import ContractorsInv from "pages/App/Members/View/components/ContractorsInv";
import useContractors from "hooks/useContractors";

export default function Create() {
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [contractor, setContractor] = useState(null);
  const [invData, setInvData] = useState()
  const { canReadContractor } = useAccessControl();
  const {
    contractorsReducer: { invoiceInv },
  } = useContractors();

  useEffect(() => {
    setPage({
      title: "Contractors",
      path: "/app/contractors",
    });
    setLoading(true);
    getContractor(id)
      .then((response) => {
        if (response.data) {
          setContractor(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getContractorsInvRequest(id)
      .then((resp) => {        
        if(resp.data){
          setInvData(resp.data)
        }
      })
  }, [id])

  useEffect(() => {

    if (location?.state?.notes) {
      setTabValue("1");
    }
  }, [location]);

  const onSubmit = async (values) => {
    try {
      if (typeof values.city_id === "object") {
        values.city_id = values.city_id?.id;
      }
      const response = await updateContractor(id, values);
      if (response.data) {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: 'Contractor Updated Successfully!',
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        }).then((result) => {
          navigate("/app/contractors");
        });
      }
    } catch (error) {
      Swal.fire({
        toast: true,
        position: "top-right",
        icon: "error",
        title: 'Error!',
        title: error?.response?.data?.message || "Something went wrong!",
        timer: 4000,
        showConfirmButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/contractors");
        }
      });
    }
  };

  return (
    <ContentBlock title="Update Contractor" icon="ion-ios-clipboard-outline">
      {loading && <div>Loading...</div>}
      {!loading && contractor && (
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab
                label="Contractor information"
                value="0"
              />
              <Tab label="Notes" value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <ContractorsForm withHeadingLine={false} onSubmitHandler={onSubmit} form={contractor} />
          </TabPanel>
          <TabPanel value="1">
            <Notes
              notableType="Contractors"
              notableId={contractor.id}
            />
          </TabPanel>
        </TabContext>
      )}
      {canReadContractor() && (
        <Collapse title="CONTRACTOR INV">
          <ContractorsInv invData={invData}/>
        </Collapse>
      )}
    </ContentBlock>
  );
}
