import React, { useEffect, useState } from "react";
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  FormControl,
  Checkbox,
} from "@mui/material";
import HeadingLine from "components/HeadingLine";
import FormField from "components/FormField";
import { calculatePriceRequest } from "api/utilities";

const PaymentInformation = ({ setForm, form, loggedIn }) => {
  const [cost, setCost] = useState(null);
  const [costYear, setCostPerYear] = useState(null);
  const [propertyCost, setPropertyCost] = useState(null);
  const [memberCost, setMemberCost] = useState(null);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const displayValue = propertyCost ? propertyCost : '10';
  
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue) && parseFloat(inputValue) > 0) {
      const _membership = { ...form.membership };
      _membership[e.target.name] = e.target.value;
      setForm({ membership: _membership });
    }
  };

  const handleChangeSubType = (e) => {
    const _membership = { ...form.membership };
    _membership[e.target.name] = e.target.value;
    _membership.subscription_no_years = 1;
    setForm({ membership: _membership });
  };

  const handleChangeProperty = (e) => {
    const _membership = { ...form.membership };
    _membership[e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setForm({ membership: _membership });
  }

  const { membership, properties } = form;

  useEffect(() => {
    if (membership.membership_type) {
      setLoadingPrice(true);
      calculatePriceRequest({
        additional_properties: properties.length - 1,
        membership_type: membership.membership_type,
        number_of_years: membership.subscription_no_years,
        new_property_cost: membership.new_property_cost,
        property_custom_cost: membership.property_custom_cost,
        membership_custom_cost: membership.membership_custom_cost,
        membership_cost: membership.membership_cost,
      })
        .then((res) => {
          setCost(res.data.price.total_cost_usd!== 0? res.data.price.total_cost_usd : '');
          setCostPerYear(res.data.price.membership_year_price);
          setPropertyCost(res.data.price.additional_properties)
          setMemberCost(res.data.price.membership_cost)
          setLoadingPrice(false);
        })
        .catch(() => {
          setLoadingPrice(false);
        });
    }
  }, [
    properties.length,
    membership.membership_type,
    membership.subscription_no_years,
    membership.new_property_cost,
    membership.membership_cost,
  ]);

  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        <HeadingLine title="Memberships Dues:" />
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={6} sx={{ ml: 2, mt: 2 }}>
          {loggedIn && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="property_custom_cost"
                  onChange={handleChangeProperty}
                  checked={membership.property_custom_cost}
                />
              }
              label="Additional Property Custom Cost"
            />
          )}
        </Grid>
        <Grid item md={5} sm={5} xs={5} sx={{ mt: 2 }}>
          {membership.property_custom_cost && (
            <FormField
              InputLabelProps={{ shrink: true, inputProps: { min: 0, max: 10 } }}
              label="Property Cost"
              type="number"
              value={membership.new_property_cost}
              name="new_property_cost"
              onChange={handleChange}
            />
          )}
        </Grid>
        <Grid item md={6} sm={6} xs={6} sx={{ ml: 2 }}>
          {membership.membership_type === "SM" && loggedIn && (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="membership_custom_cost"
                  onChange={handleChangeProperty}
                  checked={membership.membership_custom_cost}
                />
              }
              label="Membership Custom Cost"
            />
          )}
        </Grid>
        <Grid item md={5} sm={5} xs={5}>
          {membership.membership_type === "SM" && membership.membership_custom_cost && (
            <FormField
              InputLabelProps={{ shrink: true, inputProps: { min: 0, max: 10 } }}
              label="Membership Cost"
              type="number"
              value={membership.membership_cost}
              name="membership_cost"
              onChange={handleChange}
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2">
          Membership in the Club costs just ${membership.membership_cost === '' ? costYear : membership.membership_cost} per year. Additional
          residential properties you own may be included for ${membership.new_property_cost === '' ? displayValue : membership.new_property_cost} each.
        </Typography>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <FormControl>
          <RadioGroup
            name="subscription_type"
            value={membership.subscription_type}
            onChange={handleChangeSubType}
          >
            <FormControlLabel
              value="single_year"
              control={<Radio color="primary" size="small" />}
              label={"Single year subscription"}
            />
            <FormControlLabel
              value="multiple_years"
              control={<Radio color="primary" size="small" />}
              label={"Multiple year subscription"}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {membership.subscription_type === "multiple_years" && (
        <Grid item md={6} sm={12} xs={12}>
          <FormField
            //InputLabelProps={{ shrink: true }}
            InputLabelProps={{ shrink: true, inputProps: { min: 0, max: 10 } }}
            label="Number of years"
            type="number"
            //min={0}
            value={membership.subscription_no_years}
            name="subscription_no_years"
            onChange={handleChange}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        {!loadingPrice && cost && (
          <Typography variant="subtitle2" color="success.main">
            Your total membership dues are ${cost.toFixed(2)}
          </Typography>
        )}
        {loadingPrice && (
          <Typography variant="subtitle2">
            Calculating membership price ...
          </Typography>
        )}
      </Grid>
      {/* <Grid item xs={12}>
        <FormControl>
          <RadioGroup
            name="payment_method"
            value={membership.payment_method}
            onChange={handleChange}
          >
            <FormControlLabel
              value="STRIPE"
              control={<Radio color="primary" size="small" />}
              label={"Pay by Credit Card"}
            />
            <FormControlLabel
              value="CHECK"
              control={<Radio color="primary" size="small" />}
              label={"Pay by Check"}
            />
          </RadioGroup>
        </FormControl>
      </Grid> */}
    </>
  );
};
export default PaymentInformation;