import React, { useEffect, useState } from "react";
import FormField from "components/FormField";
import { Autocomplete } from "@mui/material";
import { getCitiesByStateRequest } from "api/utilities";

const CityPicker = ({ state_code, onChange, value, ...others }) => {
  const [cities, setCities] = useState([]);
  const [state, setState] = useState(state_code);
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    if (state_code) {
      setLoaded(false);
      getCitiesByStateRequest({ state_code }).then((res) => {
        setCities(res.data.cities);
        setLoaded(true);
        if (
          value &&
          res.data.cities.findIndex((o) => value.id === o.id) !== -1
        ) {
          onChange(value);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state_code]);

  useEffect(() => {
    if (state !== state_code && loaded) {
      if (value && cities.findIndex((o) => value.id === o.id) === -1) {
        onChange(null);
      }
      setState(state_code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, loaded]);

  return (
    <>
      <Autocomplete
        disablePortal
        options={cities}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        onChange={(_ev, newValue) => onChange(newValue)}
        renderInput={(params) => (
          <FormField autoComplete="false" {...others} {...params} />
        )}
      />
    </>
  );
};

CityPicker.defaultProps = {
  required: false,
};

export default CityPicker;
