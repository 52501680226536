import React from "react";
import { TableRow, TableCell, Button } from "@mui/material";

const MemberRow = ({ data ,deleteMemeberHandler}) => {
  return (
    <TableRow key={data.id}>
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.name}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.address}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.cell_phone}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.email}
      </TableCell>
      <TableCell >
        <Button
        sx={{whiteSpace:"nowrap"}}
          variant="contained"
          color="error"
          size="small"
          onClick={() => deleteMemeberHandler(data.id)}
        >
          Restore Member
        </Button>
        
      </TableCell>
    </TableRow>
  );
};

export default MemberRow;
