import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

const ContractorsInv = ({ invData }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    return (
        <React.Fragment>
            <Table size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>#ID</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Con. Code</TableCell>
                        <TableCell>Inv Number</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Action </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invData && invData?.invoice && invData?.invoice?.length > 0 ? (
                        invData?.invoice?.map((inv, i) => {
                            return (
                                <>
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            {inv?.id}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {inv?.mem_name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {inv?.con}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {inv?.hoc_inv_num}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {inv?.total}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {`${dayjs(inv?.created_at).format("MM/DD/YYYY hh:mm a")}`}
                                        </TableCell>

                                        <TableCell component="th" scope="row">
                                            <Button
                                                onClick={() => navigate(`/app/viewInvContractorHoc/${inv?.id}/${id}`)}//id?con_inv_id=5&contractor_id=23
                                            >View</Button>
                                        </TableCell>
                                    </TableRow>
                                </>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell colSpan={7} align="center">
                                No data
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </React.Fragment>
    );
};
export default ContractorsInv;