import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import TableFooterWithPagination from 'components/TableFooterWithPagination';
import useCheckPayment from 'hooks/useCheckPayment';
import React, { useEffect } from 'react'
import CheckRow from './CheckRow';
import Swal from 'sweetalert2';
import { deleteCheckRequest } from 'api/check';
import useApplication from 'hooks/useApplication';

const InvoicesCheckPayment = () => {
    const {
        checkReducer: { results, loading, meta, query },
        search,
        changePage,
        changePerPage,
        getChecks,
    } = useCheckPayment();
    const { setPage } = useApplication();

    useEffect(() => {
        setPage({
        
          path: "/app/invoices/checkPayment",
        });
               // eslint-disable-next-line
      }, []);
    

    useEffect(() => {
        search(query);
    }, []);

    const handleSearch = (value) => {
        search(value);
    };

    const handleChangePage = (_event, newPage) => {
        changePage(newPage);
        getChecks({
            search: query,
            page: newPage + 1,
            per_page: meta.per_page,
        });
    };

    const handleChangeRowsPerPage = (event) => {
        changePerPage(event);
        getChecks({
            search: query,
            page: meta.current_page,
            per_page: parseInt(event.target.value),
        });
    };

    const deleteMemeberHandler = (invoice_id) => {
        Swal.fire({
            title: "Delete check?",
            text: "You won't be able to revert this!",
            icon: "warning",
            customClass: {
                title: "swal-text",
                popup: "swal-text"

            },
            fontFamily: "Open Sans",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            console.log(result, 'result');
            if (result.isConfirmed) {
                deleteCheckRequest(invoice_id)
                    .then(() => {
                        search();
                        Swal.fire({
                            toast: true,
                            timer: 3000,
                            position: "top-right",
                            title: 'Check Deleted Successfully!',
                            showConfirmButton: false,
                            icon: "success"
                        })
                    })
                    .catch((e) => { });
            }
        });
    };

    return (
        <>
            {loading ? (
                <CircularProgress style={{ margin: "20px auto", display: "block" }} />
            ) : (
                <Box sx={{ overflow: "auto" }}>
                    <Table size="small" aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>#ID</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>First Name</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>Last Name</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>Check Number</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>Amount </TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>Bank Name</TableCell>
                                <TableCell sx={{ whiteSpace: "nowrap" }}>Account Number</TableCell>
                                <TableCell sx={{ paddingRight: 15,     padding: '5px 34px'}}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results?.map((check) => (
                                <CheckRow
                                    key={check?.id}
                                    check={check}
                                    deleteMemeberHandler={deleteMemeberHandler}
                                    loading={loading}
                                />
                            ))}
                            {!results.length && (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No data
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                        <TableFooterWithPagination
                            count={meta.total}
                            rowsPerPage={meta.per_page}
                            page={meta.current_page - 1}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Table>
                </Box>
            )}
        </>
    )
}
export default InvoicesCheckPayment;