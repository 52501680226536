import React, { useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  CircularProgress
} from "@mui/material";
import MemberRow from "./DeletedMemberRow";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import Swal from "sweetalert2";
import useMembership from "../../../../../hooks/useMembership";

export default function DeletedMembers() {
  const {
    membershipReducer: { query, meta,deletedMembers,loading },
    changePage,
    changePerPage,
    getDeletedMembers,restoreDeletedMembers
  } = useMembership();
  
  useEffect(() => {
    getDeletedMembers()
  }, []);

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getDeletedMembers({
      search: query,
      page: newPage + 1,
      per_page: meta.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getDeletedMembers({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  // Deleted member restoration
  const deleteMemeberHandler=(id)=>{
    Swal.fire({
      showConfirmButton:true,
      showCancelButton:true,
      icon:'warning',
      title:`Are you sure want to restore Member with ID: ${id}?`,
    }).then(res=>{
      if(res.isConfirmed){
        restoreDeletedMembers({id:id}).then(res=>{
          if(res){
            Swal.fire({
              toast:true,
              timer:3000,
              title:'Record Restored',
              showConfirmButton:false,
              icon:"success"
            })
          } else {
            Swal.fire({
              toast:true,
              position:"top-right",
              text: 'Member Restored Successfully!',
              icon:"success",
              timer:4000,
              showConfirmButton:false
            })
          }
        }).catch(error => {
          Swal.fire({
            icon:"error",
            text: 'Member restore unsuccessful',
            confirmButtonText: 'OK'
          })
        });
      }
    })
    
  }
  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <>
          <Box display="flex" justifyContent="flex-end">
            {/* <SearchInput
                  defaultValue={query}
                  onChange={handleSearch}
                  placeholder="By ID, First Name, Last Name, Phone or Email"
              /> */}
            {/* {canCreateMember() && (
                  <Button
                      sx={{ ml: 2 }}
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => navigate("/app/members/create")}
                  >
                      Add member
                  </Button>
              )} */}
          </Box>
          <Box sx={{overflow:"auto"}}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>#ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                {/* <TableCell>Status</TableCell> */}
                <TableCell >Action</TableCell>
                {/* <TableCell align="right"></TableCell> */}
              </TableRow>
              {/* deleteMemeberHandler={deleteMemeberHandler} */}
            </TableHead>
            <TableBody>
              {deletedMembers?.map((member) => (
                <MemberRow
                  key={member?.membership_id}
                  data={member}
                  deleteMemeberHandler={deleteMemeberHandler}
                />
              ))}
              {!deletedMembers?.length && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {deletedMembers?.length > 0 && (
              <TableFooterWithPagination
                count={meta.total}
                rowsPerPage={meta.per_page}
                page={meta.current_page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Table>
          </Box>
        </>
      )}
    </>
  );
}
