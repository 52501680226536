import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button, Chip, Avatar } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import useUser from "hooks/useUser";
import { Toaster } from "react-hot-toast";
import useAccessControl from "hooks/useAccessControl";

const UserRow = ({ data, deleteUserData }) => {
  const navigate = useNavigate();
  const {
    canDeleteUser,
    canUpdateUser
  } = useAccessControl();
  const defaultUser = {
    bgColor: "lightgray",
    txtColor: "black",
    name: "Unassigned",
  };

  return (
    <TableRow key={data.uuid}>
      <TableCell component="th" scope="row" width="5%">
        {data.id}
      </TableCell>
      <TableCell component="th" scope="row" width="20%">
        <Chip
          sx={{
            mt: 1,
            justifyContent: "flex-start",
            background: data.bgColor ?? defaultUser.bgColor,
            color: data.txtColor ?? defaultUser.txtColor,
          }}
          avatar={
            <Avatar size="small">
              <PersonIcon sx={{ color: "#fff" }} />
            </Avatar>
          }
          label={data.name ?? defaultUser.name}
        />
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {data.email}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {data.role}
      </TableCell>
      <TableCell component="th" scope="row" width="5%">
        {data.extension}
      </TableCell>
      <TableCell  component="th" scope="row" align="right" width="20%">
        <div style={{display:"flex",gap: "8px",width:"100%"}}>
        { canUpdateUser() &&
          
          <Button
          style={{marginBottom:"3px", width:"0px", marginRight:"3px"}}
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 0.5}}
          onClick={() => navigate(`/app/users/${data.uuid}`)}
        >
          Edit
        </Button>
        }
        { canDeleteUser() &&
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => deleteUserData(data.id)}
        >
          Delete
        </Button>
        }
        </div>
      </TableCell>
      <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{duration: 5000}}
        />
    </TableRow>
  );
};

export default UserRow;
