import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import useApplication from "hooks/useApplication";
import useInvoices from "hooks/useInvoices";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import Filter from "../components/Filter";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import InvoicesCheckPayment from "./components/InvoicesCheckPayment";
import InvoiceData from "./components/InvoiceData";

export default function Invoices() {
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();
  const {
    invoicesReducer: { filter, search, meta },
    searchInvoices,
    changePage,
    changePerPage,
    getInvoices,
  } = useInvoices();

  useEffect(() => {
    setPage({
      title: "Invoices",
      path: "/app/invoices/create",
    });
    searchInvoices({ search, page: meta.current_page, per_page: meta.per_page });
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    searchInvoices({ search: value, page: meta.current_page, per_page: meta.per_page });
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getInvoices({ search, page: newPage + 1, per_page: meta.per_page });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getInvoices({
      search,
      page: meta.current_page,
      per_page: parseInt(event.target.value)
    });
  };

  const handleFilter = (values) => {
    getInvoices({
      filter: values,
      search,
      page: meta.current_page,
      per_page: meta.per_page
    });
  };

  return (
    <PaperBlock
      title="Invoices"
      actions={
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center" className="memberSearchBox">
            <Filter values={filter} onChange={handleFilter} />
            <SearchInput
              defaultValue={search}
              onChange={handleSearch}
              placeholder="By ID, First Name, Last Name, Phone or Email"
            />
          </Box>
        </>
      }
    >
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab
                label="Invoices"
                value="0"
              />
              <Tab
                label="Check Payment"
                value="1"
              />
              {/* <Tab
                label="Delete Checks"
                value="2"
              /> */}
            </TabList>
          </Box>
          <TabPanel value="0">
            <InvoiceData />
          </TabPanel>
          <TabPanel value="1">
            <InvoicesCheckPayment />
          </TabPanel>
        </TabContext>
      </Box>
    </PaperBlock>
  );
}
