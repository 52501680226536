
import { searchZipDataRequest } from "api/utilities";
import { useEffect } from "react";
import { useFormik } from "formik";

const ShippingFields = ({
  shippingData,
  onChange = () => {},
  withActions = false,
  errors = {}
}) => {

  const formik = useFormik({
    initialValues: {
      city_id: shippingData?.city_id || null,
      ship_date: shippingData?.ship_date || null,
      ship_to_line_1: shippingData?.ship_to_line_1 || '',
      ship_to_line_2: shippingData?.ship_to_line_2 || '',
      ship_to_line_3: shippingData?.ship_to_line_3 || '',
      ship_to_line_4: shippingData?.ship_to_line_4 || '',
      ship_to_city: shippingData?.ship_to_city || {},
      ship_to_state: shippingData?.ship_to_state || '',
      ship_to_postal_code: shippingData?.ship_to_postal_code || '',
    },
  });

  useEffect(() => {
    onChange && onChange(formik.values);
  }, [formik.values])

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      ship_date: shippingData?.ship_date || null,
      ship_to_line_1: shippingData?.ship_to_line_1 || '',
      ship_to_line_2: shippingData?.ship_to_line_2 || '',
      ship_to_line_3: shippingData?.ship_to_line_3 || '',
      ship_to_line_4: shippingData?.ship_to_line_4 || '',
      ship_to_city: shippingData?.ship_to_city || null,
      ship_to_state: shippingData?.ship_to_state || '',
      ship_to_postal_code: shippingData?.ship_to_postal_code || '',
      ship_to_country: shippingData?.ship_to_country || '',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const setDefaults = () => {
  //   formik.setValues({
  //     city: {
  //       id: defaultLocation.city.id,
  //       label: defaultLocation.city.city,
  //     },
  //     state: defaultLocation.state.code,
  //     zip: defaultLocation.zip
  //   });
  // };

  const handleZipChange = (e) => {
    searchZipDataRequest(e.target.value)
      .then(({ data }) => {
        formik.setValues({
          ...formik.values,
          city_id: data.details.city.id,
          ship_to_state: data.details.state.code,
          ship_to_postal_code: data.details.zip,
          ship_to_city: {
            id: data.details.city.id,
            label: data.details.city.city,
          }
        }).then(() => {
          formik.setErrors({ ...formik.errors, ship_to_postal_code: undefined});
        })
      })
      .catch((error) => {
        formik.setValues({
          ...formik.values,
          city_id: null,
          ship_to_state: '',
          ship_to_city:  null,
          ship_to_postal_code: ''
        }).then(() => {
          if (error?.response?.data?.errors) {
            Object.keys(error.response.data.errors).forEach((field) => {
              if (error.response.data.errors[field][0] !== undefined) {
                formik.setFieldError(field, error.response.data.errors[field][0]);
              }
            })
          }
        })
      });
  };

  return (
    <>
      {/* <Box mb={4}>
        <Grid container spacing={2}>
          <Grid item lg={12 } md={12} sm={12} xs={12}>
            <HeadingLine title="Shipping Information:" />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Ship To Line 1"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formik.values.ship_to_line_1}
              onChange={(e) => formik.setFieldValue('ship_to_line_1', e.target.value)}
              error={Boolean(formik.errors.ship_to_line_1)}
              helperText={formik.errors.ship_to_line_1}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Ship To Line 2"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formik.values.ship_to_line_2}
              onChange={(e) => formik.setFieldValue('ship_to_line_2', e.target.value)}
              error={Boolean(formik.errors.ship_to_line_2)}
              helperText={formik.errors.ship_to_line_2}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Ship To Line 3"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formik.values.ship_to_line_3}
              onChange={(e) => formik.setFieldValue('ship_to_line_3', e.target.value)}
              error={Boolean(errors.ship_to_line_3)}
              helperText={errors.ship_to_line_3}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Ship To Line 4"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formik.values.ship_to_line_4}
              onChange={(e) => formik.setFieldValue('ship_to_line_4', e.target.value)}
              error={Boolean(errors.ship_to_line_4)}
              helperText={errors.ship_to_line_4}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Country"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={formik.values.ship_to_country}
              onChange={(e) => formik.setFieldValue('ship_to_country', e.target.value)}
              error={Boolean(errors.ship_to_country)}
              helperText={errors.ship_to_country}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <CityPicker
              label="City"
              // required
              InputLabelProps={{ shrink: true }}
              value={formik.values.ship_to_city || ''}
              name="ship_to_city"
              state_code={formik.values.ship_to_state}
              onChange={(val) => {
                formik.setValues({
                  ...formik.values,
                  ship_to_city: val,
                  city_id: val?.id
                });
              }}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <StatePicker
              InputLabelProps={{ shrink: true }}
              name="ship_to_state"
              label="State"
              value={formik.values.ship_to_state}
              onChange={(e) => formik.setFieldValue('ship_to_state', e.target.value)}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <FormField
              label="Zip Code"
              name="ship_to_postal_code"
              value={formik.values.ship_to_postal_code}
              onChange={(e) => formik.setFieldValue('ship_to_postal_code', e.target.value)}
              onBlur={handleZipChange}
              error={Boolean(formik.errors.ship_to_postal_code) || Boolean(formik.errors.zip)}
              helperText={formik.errors.ship_to_postal_code || formik.errors.zip}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Ship Date"
                inputFormat="MM/dd/yyyy"
                value={formik.values.ship_date}
                onChange={(date) => formik.setFieldValue('ship_date', dayjs(date).format("YYYY-MM-DD"))}
                renderInput={(params) => (
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    fullWidth
                    size="small"
                    disabled={!withActions}
                    error={Boolean(errors.ship_date)}
                    helperText={errors.ship_date}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default ShippingFields;
