import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button, Chip } from "@mui/material";
import { statuses } from "utils/jobStatuses";
import AssignedToJob from "components/AssignedToJob";
import useQueue from "hooks/useQueue";
import Toast from "components/Toast";

const JobRow = ({ data, deleteJobData, value }) => {
  const { changeTaskUser } = useQueue();
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.code?.description}
      </TableCell>
      <TableCell component="th" scope="row">
        {`${data.contact?.first_name || ''} ${data.contact?.last_name || ''}`}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.membership_id}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.property?.address}
      </TableCell>
      <TableCell component="th" scope="row">
        <Chip
          label={data.status}
          style={{ background: statuses[data.status], color: "white" }}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        <AssignedToJob
          value={data.assigned_to ?? 0}
          onChange={(e) => {
            data.assigned_to = e.target.value;
            changeTaskUser(data?.id, e.target.value, module = "job", data.code?.description);
            Toast.fire({
              title: 'Assigned To Updated Successfully!',
              icon: "success",
              position: "top-right",
              timer: 3000
            });
          }}
        />
      </TableCell>
      <TableCell component="th" scope="row" >
        <div style={{ display: "flex", gap: "8px", width: "100%", marginLeft: "20px" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ mr: 2.0 }}
            onClick={() => navigate(`/app/jobs/${data.id}`)}
          >
            View
          </Button>

          <Button
            variant="contained"
            color="error"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => deleteJobData(data.id)}
          >
            Delete
          </Button>

        </div>
      </TableCell>
    </TableRow>
  );
};

export default JobRow;
