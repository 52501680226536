import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Box
} from "@mui/material";
import ContractorRow from "./components/ContractorRow";
import { useEffect } from "react";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import useMembershipshipCallLog from "hooks/useMembershipCallLog";

//import {getAllCallRecord} from  "./components/fakeData";

export default function Calllog({ fileable_type, fileable_id }) {

  const {
    getMemberships,
    membershipCallLogReducer: { results, query, meta, loading },
    changePage,
    changePerPage,
  } = useMembershipshipCallLog();

  useEffect(() => {
    getMemberships()
  }, []);

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getMemberships({
      search: query,
      page: newPage + 1,
      perPage: meta.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getMemberships({
      search: query,
      page: meta.current_page,
      perPage: parseInt(event.target.value),
    });
  };

  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>ID</TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Date</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((contractor, i) => (
                <ContractorRow
                  key={contractor.uuid}
                  data={contractor}
                />
              ))}
              {!results.length && (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooterWithPagination
              count={meta.total}
              rowsPerPage={meta.per_page}
              page={meta.current_page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Table>
        </Box>
      )}
    </>
  );
}

Calllog.defaultProps = {
  fileable_type: "Membe",
  fileable_id: "1asfdsfsfdsfdsf"
};
