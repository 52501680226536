import React, { useEffect, useState } from "react";
import ContentBlock from "components/ContentBlock";
import useApplication from "hooks/useApplication";
import { Button, Box, Grid, Typography } from "@mui/material";
import LinkBtn from "components/LinkBtn";
import MemberApplicationContext from "components/MembershipForm/MemberApplicationFormContext";
import {
  contact,
  initialValues,
  initialValuesMethoods,
  property,
} from "components/MembershipForm/formConfig";
import { checkZipRequest } from "api/utilities";
import { saveApplicationRequest } from "api/membership";
import FormField from "components/FormField";
import LoadingButton from "components/LoadingButton";
import useStyles from "./styles-jss";
import MembershipForm from "components/MembershipForm/MembershipForm";
import { useNavigate } from "react-router-dom";
import Toast from "components/Toast";
import * as Yup from "yup"

const memberValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required"),
  email: Yup.string().email("Please enter valid email").required("This field is required"),
  home_phone: Yup.string(),
  cell_phone: Yup.string().required("This field is required"),
  work_phone: Yup.string(),
  work_phone_extension: Yup.string(),
  occupation: Yup.string(),
  company: Yup.string(),
});

const propertiesValidationSchema = Yup.object().shape({
  address: Yup.string().required("This field is required"),
  state: Yup.string(),
  zip: Yup.string(),
});

const membershipValidationSchema = Yup.object().shape({
  membership_card: Yup.string(),
  newsletter: Yup.string(),
  communications: Yup.string(),
  invoice_preference: Yup.string(),
  membership_type: Yup.string(),
  about_club: Yup.string(),
  mortgage_holder: Yup.string(),
  years_in_home: Yup.string(),
  checking_savings: Yup.string(),
  branch: Yup.string(),
  subscription_type: Yup.string(),
  subscription_no_years: Yup.number(),
  payment_method: Yup.string(),
  paperless_communication: Yup.boolean(),
  terms_acceptance: Yup.boolean("This field is required"),
  mailing_address_as_home: Yup.boolean().required("This field is required"),
  out_of_network: Yup.boolean(),
  date: Yup.string(),
});

const membersFormSchema = Yup.object().shape({
  members: Yup.array().of(memberValidationSchema),
  properties: Yup.array().of(propertiesValidationSchema),
  membership: membershipValidationSchema,
})

const CreateMember = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setPage } = useApplication();
  useEffect(() => {
    setPage({
      title: "Member Application",
      path: "/app/members",
    });
    // eslint-disable-next-line
  }, []);

  const resetForm = () => {
    setForm(initialValues);
  };

  const [state, _setState] = useState({
    validZip: false,
    zip: "98112",
    zipLoading: false,
    showModal: false,
    zipData: {},
    formLoading: false,
    completed: false,
  });

  const [validationErrors, setErrors] = useState({})

  const [form, _setForm] = useState(initialValuesMethoods());

  const setForm = (values) => _setForm({ ...form, ...values });

  const setState = (values) => _setState({ ...state, ...values });

  const addMember = () => {
    const _members = form.members;
    _members.push({ ...contact });
    setForm({ members: _members });
  };

  const addProperty = () => {
    const _properties = form.properties;
    _properties.push({ ...property });
    setForm({ properties: _properties });
  };

  const checkZip = () => {
    if (state.zip) {
      setState({ zipLoading: true });
      checkZipRequest({ zip: state.zip })
        .then((resp) => {
          setState({
            zipData: resp.data.details,
            validZip: true,
            zipLoading: false,
          });
        })
        .catch((error) => {
          console.log("error", error.response.data)
          setState({
            zipData: error.response.data,
            validZip: false,
            zipLoading: false,
          });
        });
    }
  };

  const validateField = async (name, value) => {
    try {
      let copy = { ...validationErrors };

      await membersFormSchema.validateAt(name, form);
      delete copy[name];
      setErrors({ ...copy });
    } catch (error) {
      setErrors({ ...validationErrors, [name]: error.message });
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await membersFormSchema.validate(form, { abortEarly: false });
      setState({ formLoading: true });

      const formData = new FormData()
      // Append members data
      form.members.forEach((item, index) => {
        for (let key in item) {
          formData.append(`members[${index}][${key}]`, item[key]);
        }
      })

      form.properties.forEach((item, index) => {
        for (let key in item) {
          if (key === "city") {
            for (let ke in item[key]) {
              formData.append(`properties[${index}][${key}][${ke}]`, item[key][ke]);
            }
          } else {
            formData.append(`properties[${index}][${key}]`, item[key]);
          }
        }
      })
      // Append mailing_address data
      for (let key in form.mailing_address) {
        formData.append(`mailing_address[${key}]`, form.mailing_address[key] || "");
      }
      for (let key in form.mailing_address) {
        formData.append(`mailing_address[${key}]`, form.mailing_address[key] || "");
      }
      // Append membership data
      delete form.membership.fileURL
      for (let key in form.membership) {
        formData.append(`membership[${key}]`, form.membership[key]);
      }

      saveApplicationRequest(formData)
        .then((res) => {
          setState({ formLoading: false, completed: res.data.membership });
          if (res.data.membership.invoice?.payment_method === "STRIPE") {
            navigate(`/app/members`);
          } else {
            navigate(`/app/members/${res.data.membership.membership_id}`);
          }
          Toast.fire({
            position: "top-right",
            timer: 4000,
            icon: "success",
            title: "Member Application Completed!",
          });
        })
        .catch((error) => {
          setState({ formLoading: false });
          Toast.fire({
            position: "top-right",
            timer: 4000,
            icon: "error",
            title: error?.response?.data?.message || "Email has already been taken.",
          });
        });
    } catch (error) {
      let newErrors = {};
      error &&
        error.inner &&
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      setErrors(newErrors)
    }
  };

  const { validZip } = state;

  return (
    <ContentBlock
      title="Member Application"
      rightActions={
        <Button
          component={LinkBtn}
          to="/app/members"
          color="primary"
          size="small"
          variant="contained"
          sx={{
            ':hover': { color: 'white', },
          }}
        >
          Cancel
        </Button>
      }
    >
      <section className={classes.formSection}>
        <Grid container>
          <Grid item md={12}>
            <Typography variant="subtitle2">
              Enter your zip code to confirm your property is in the Club's
              primary service area:
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Box alignItems="center" display="flex" pt={1}>
              <Box mr={2}>
                <FormField
                  value={state.zip}
                  onChange={(e) => setState({ zip: e.target.value })}
                  onKeyPress={(e) => {
                    if (e.key === ' ' || e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                  fullWidth={false}
                  size="small"
                  label="Zip Code"
                />
              </Box>
              <Box>
                <LoadingButton
                  loading={state.zipLoading}
                  onClick={checkZip}
                  className={classes.zipBtn}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  Check Zip Code
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
          {!validZip
            && state.zipData.hasOwnProperty("errors")
            && (
              <Grid item md={12}>
                <Typography
                  paddingTop={1}
                  className={classes.errorText}
                  variant="subtitle2"
                >
                  {state.zipData.errors
                    //.zip[0]
                  }
                </Typography>
              </Grid>
            )}
          {validZip && (
            <Grid item md={12}>
              <Typography
                paddingTop={1}
                className={classes.successText}
                variant="subtitle2"
              >
                The property in (input value) appears to be located Partial from
                the Club's primary service area
              </Typography>
            </Grid>
          )}
        </Grid>
      </section>
      <section className={classes.formSection}>
        {validZip && (
          <MemberApplicationContext.Provider
            value={{
              form,
              setForm,
              defaultLocation: state.zipData,
              addMember,
              addProperty,
              handleSubmit,
              validationErrors,
              formLoading: state.formLoading,
              validateField
            }}
          >
            <MembershipForm />
          </MemberApplicationContext.Provider>
        )}
      </section>
    </ContentBlock>
  );
};
export default CreateMember;
