import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ContractorRow from "./components/ContractorRow";
import useMembership from "hooks/useMembership";
import TableFooterWithPagination from "components/TableFooterWithPagination";

export default function Contract({ fileable_type, fileable_id }) {

  const {
    search,
    getMemberships,
    membershipReducer: { results, loading, query, meta },
    changePage,
    changePerPage,
  } = useMembership();


  useEffect(() => {
    // setPage({
    //   title: "Members Directory",
    //   path: "/app/members",
    // });
    search(query);
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getMemberships({
      search: query,
      page: newPage + 1,
      per_page: meta.per_page,
    });
  };


  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getMemberships({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };


  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
       <Box sx={{overflow:"auto"}}>
         <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#ID</TableCell>
              <TableCell>name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Message</TableCell>
              <TableCell>Call</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.map((contractor) => (
              <ContractorRow
                key={contractor.membership_id}
                data={contractor}
              // deleteContractor={deleteContractorHandler}
              />
            ))}
            {!results.length && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No data
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {results.length > 0 && (
            <TableFooterWithPagination
              count={meta.total}
              rowsPerPage={meta.per_page}
              page={meta.current_page - 1}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Table>
       </Box>
      )}
    </>
    // </ContractorBlocks>
    // </Box>
  );
}

Contract.defaultProps = {
  fileable_type: "Membe",
  fileable_id: "1asfdsfsfdsfdsf"
};

