import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import brand from "theme/brand";
import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import * as Yup from "yup";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import useStyles from "../main-jss";
import useAuth from "hooks/useAuth";
import Swal from "sweetalert2";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ForgotPassword() {
  const classes = useStyles();
  const { forgotPassword, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const AccountDetailsValidationSchema = Yup.object().shape({
    email: Yup.string().email("Please enter valid email").required("This field is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      termsAgree: true,
    },
    validationSchema: AccountDetailsValidationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      setLoading(true);
      forgotPassword(values.email, (success, error) => {
        setLoading(false);
        if (!error) {
          setSubmitting(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: success.data.message || 'Reset Password link sent',
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          setSubmitting(false)
          setLoading(false);
          Swal.fire({
            icon: 'error',
            toast: true,
            position: 'top-right',
            timer: 3000,
            title: error?.response?.data?.data?.email || 'Something went wrong!',
            showConfirmButton: false
          });
        }
      });
    },
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <Paper className={classes.paperWrap}>
            <Box justifyContent="space-between" display="flex" ml={3} pb={2} >
              <NavLink to="/" className={classes.brand}>
                <ArrowBackIcon />
              </NavLink>
            </Box>
            <div className={classes.topBar}>
              <NavLink to="/" className={classes.brand}>
                <img src={brand.logo} alt={brand.name} />
              </NavLink>
            </div>
            <Typography variant="h5" fontWeight={600} className={classes.title} gutterBottom>
              Did you forget your password
            </Typography >
            <Typography fontWeight={500} className={classes.title} gutterBottom>
              Enter your email address you're using for your account below
            </Typography>
            {/* TODO: socmedLogin */}
            <section className={classes.formWrap}>
              <form onSubmit={formik.handleSubmit}>
                <Box pt={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      size="medium"
                      name="email"
                      label={t("auth:email_label")}
                      variant="outlined"
                      placeholder={t("auth:email_placeholder")}
                      color="secondary"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email) || !!error
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon
                              color="secondary"
                              className={classes.inputIcon}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
                <div className={classes.btnArea}>
                  <Button
                    disabled={submitting}
                    variant="contained"
                    fullWidth
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    Send Reset Link
                    {loading && (
                      <Box pl={4}>
                        <CircularProgress color="secondary" size={22} />{" "}
                      </Box>
                    )}
                  </Button>
                </div>
              </form>
            </section>
          </Paper>
        </div>
      </div>
    </div>
  );
}
