import React from "react";
import dayjs from "dayjs";
import { TableRow, TableCell,Chip } from "@mui/material";
import { callStausColors } from "utils/queue";

const ContractorRow = ({ data}) => {
  return (
    <TableRow
    >
      <TableCell component="th" scope="row" width="25%">
        {data.from.name || "Unknown"}
      </TableCell>
      <TableCell component="th" scope="row" width="20%">
        {data.from.phoneNumber || data.from.extensionNumber }
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        <Chip
          sx={{
            mt: 1,
            justifyContent: "flex-start",
            background: callStausColors[data.result],
            color: "#fff",
          }}
          label={data.result}
        />
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {data.duration}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {`${dayjs(data.startTime).format("MM/DD/YYYY hh:mm a")}`}
      </TableCell>
    </TableRow>
  );
};

export default ContractorRow;
