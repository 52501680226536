import React, { useEffect, useState } from "react";
import { Box, Tab, useTheme } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApplication from "hooks/useApplication";
import useBreadcrumb from "hooks/useBreadcrumb";
import { createJobRequest, getJob } from "api/jobs";
import Swal from "sweetalert2";
import useJobs from "hooks/useJobs";
import ContentBlock from "components/ContentBlock";
import Notes from "components/Notes";
import JobStatus from "components/JobStatus";
import FileManager from "components/FileManager";
import { removeContractorRequest } from "api/jobs";
import Estimates from "./components/Estimates";
import ViewJob from "./components/ViewJob";
import ".././../../../utils/sweetalert.css"

function Jobs() {
  const { setPage } = useApplication();
  const location = useLocation();
  const navigate = useNavigate();
  const { setBreadcrumbCustomPlace } = useBreadcrumb();
  const { job_uuid } = useParams();
  const { changeJobStatus } = useJobs();
  const theme = useTheme();

  useEffect(() => {
    setPage({
      title: "Jobs Directory",
      path: "/app/jobs",
    });
    // eslint-disable-next-line
  }, []);

  const [job, setJob] = useState({});
  
  const [state, _setState] = useState({
    activeTab: "0",
    loading: true,
  });

  const setState = (values) => _setState({ ...state, ...values });
  const changeJob = (values) => setJob({ ...job, ...values });

  const handleChange = (_event, newValue) => {
    setState({ activeTab: newValue });
  };

  const getData = () => {
    getJob(job_uuid).then((res) => {
      setJob(res.data);
      setState({ loading: false });
    });
  };

  useEffect(() => {
    getData();
    setBreadcrumbCustomPlace(`#${job_uuid}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job_uuid]);

  useEffect(() => {
    if(location?.state?.notes) {
      setState({ activeTab: "2" })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleDuplicateJob = (status) => {
    createJobRequest({
      ...job,
      status
    }).then((res) => {
      if(res.data.id) {
        navigate(`/app/jobs/${res.data.id}`)
        Swal.fire({
          title: "Success",
          text: "Job Duplicated Successfully!",
          customClass: {
            title: "swal-text",
            popup:"swal-text"
            
          },
          confirmButtonColor: theme.palette.primary.main,
        });
      }
    });
  }

  const removeContractor = () => {
    removeContractorRequest(job.id).then((res) => {
      changeJob(res.data);
    });
  };

  return (
    <ContentBlock
      title="Jobs"
      loading={state.loading}
      icon="ion-ios-construct-outline"
    >
      {job.id && (
        <TabContext value={state.activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="Job Sheet Details" value="0" />
              {/* <Tab label="Estimates" value="1" /> */}
              <Tab label="Notes" value="2" />
              <Tab label="Files" value="3" />
              <Box display="flex" flexGrow={1} justifyContent={"flex-end"}>
                <JobStatus
                  showLabel={false}
                  job={job}
                  changeJobStatus={(status) => {
                    if(status === 'DUPLICATE') {
                      Swal.fire({
                        title: 'Are you sure?',
                        text: "You won't be able to revert this!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        customClass: {
                          title: "swal-text",
                          popup:"swal-text"
                        },
                        confirmButtonText: 'Yes, Confirm'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleDuplicateJob(status);
                        }
                      })
                    } else {
                      changeJobStatus(job.id, status);
                      if (status === 'RESERVICE') {
                        Swal.fire({
                          title: 'Are you sure?',
                          text: "Please confirm that you want to re-service, this action will require another estimate",
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          customClass: {
                            title: "swal-text",
                            popup:"swal-text"
                          },
                          confirmButtonText: 'Yes,Reservice'
                        }).then((result) => {
                          if (result.isConfirmed) {
                            removeContractor();
                          }
                        })
                      }
                    }
                    Swal.fire({
                      title: 'Are you sure?',
                      text: "You won't be able to revert this!",
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      customClass: {
                        title: "swal-text",
                        popup:"swal-text"
                      },
                      confirmButtonText: 'Yes, Confirm'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        changeJob({ status: status });
                      }
                    })
                  }}
                />
              </Box>
            </TabList>
          </Box>
          <TabPanel value="0">
            <ViewJob
              getData={getData}
              job={job}
              changeJob={changeJob}
              removeContractor={removeContractor}
            />
          </TabPanel>
          <TabPanel value="1">
            <Estimates job={job} onChange={() => getData()} />
          </TabPanel>
          <TabPanel value="2">
            <Notes notableType="Job" notableId={job.id} />
          </TabPanel>
          <TabPanel value="3">
            <FileManager fileable_id={job.id} fileable_type="Job" />
          </TabPanel>
        </TabContext>
      )}
    </ContentBlock>
  );
}

export default Jobs;
