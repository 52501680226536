import React, { useCallback } from 'react';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { CreateRcExt, DeleteRcExt } from 'api/connect';
import Swal from 'sweetalert2';


const RingCentralAccount = ({
  readOnly,
  user,
}) => {
  const [extension1, setExtension1] = React.useState(user.extension);
  const [extension, setExtension] = React.useState();
  const [rcpass, setrcpass] = React.useState(user.rc_pass);
  const [loading, setLoading] = React.useState(false);

  const handleChange = useCallback(() => {
    if (extension >= 3 && extension ) {
      setLoading(true);
      CreateRcExt({
        id: user.id,
        extensionNumber: extension,
        password: rcpass,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email
      })
        .then(res => {
          setLoading(false);
          Swal.fire({
            toast:true,
            position:"top-right",
            timer:4000,
            icon: 'success',
            title: 'Extension Created Successfully!',
            showConfirmButton:false
          });
          setExtension('')
          setExtension1(res.data.extensionNumber)
        }).catch(err => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            toast:true,
            position:"top-right",
            timer:4000,
            title: err.response.data.message||'Extension number already exists',
            showConfirmButton:false
          });
        });
    } else {
      Swal.fire({
        icon: 'error',
        toast:true,
        position:"top-right",
        timer:4000,
        title: 'Please enter the value',
        showConfirmButton:false
      });
    }

  }, [rcpass, extension, user]);


  const DeleteExt = () => {
    try {
      Swal.fire({
        title: "Delete User Extension?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        customClass: {
          title: "swal-text",
          popup: "swal-text"
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setLoading(true);
          DeleteRcExt({ extension: extension1 })
            .then(res => {
              // setExtension2(res)
              setLoading(false);
              Swal.fire({
                icon: 'success',
                toast:true,
                position:"top-right",
                showConfirmButton:false,
                timer:4000,
                title: 'Extension Removed Successfully!',
                showConfirmButton:false
              });
              setExtension1(res.data.extension)
            }).catch(err => {
              Swal.fire({
                icon: 'error',
                toast:true,
                position:"top-right",
                timer:4000,
                text: 'There was an error processing your request.',
              });
            });
        }
      });
    } catch (error) {
      console.log("error", error);
    }

  }

  return (
    <Box sx={{ width: { sx: "100%", md: "40%" } }}>
      <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mb: 2 }}>
        RingCentral Account
      </Typography>
      <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}> </Typography>
      {!extension1 ? (
        <Box variant="small">
          <TextField disabled={readOnly} size='small' fullWidth label="Extension" variant="outlined" value={extension} onChange={(e) => setExtension(e.target.value)} />
          {/* <TextField
            type={showPassword ? "text" : "password"}
            sx={{ mt: 2 }}
            disabled={readOnly}
            size='small'
            fullWidth label="Password"
            variant="outlined"
            value={rcpass} onChange={(e) => setrcpass(e.target.value)}
            InputProps={
              {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((prevPass) => !prevPass)}
                    >
                      {showPassword ? (
                        <VisibilityOff color="secondary" />
                      ) : (
                        <Visibility color="secondary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
          /> */}
          {!readOnly && (
            <Button variant="contained" color="primary" onClick={() => handleChange()} sx={{ mt: 2 }} >
              Create User Extension
              {loading && (
                <Box pl={2}>
                  <CircularProgress color="success" size={16} />{" "}
                </Box>
              )}
            </Button>
          )}
        </Box>
      ) : (
        <Box variant="small">
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
            Extension: {extension1}
          </Typography>
          <Button variant="contained" color="error" onClick={() => DeleteExt()} sx={{ mt: 2 }} >
            Delete User Extension
            {loading && (
              <Box pl={2}>
                <CircularProgress size={16} />{" "}
              </Box>
            )}
          </Button>
        </Box>
      )}
    </Box>
  )
}


RingCentralAccount.defaultProps = {
  readOnly: false
};

export default RingCentralAccount;