import React from "react";
import {
  Box,
  Button,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  Grid,
  Chip,
} from "@mui/material";
import ".././../utils/sweetalert.css"
import CircleIcon from "@mui/icons-material/Circle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import debounce from "lodash.debounce";
import FormField from "components/FormField";
import useUser from "hooks/useUser";
import { tasksColors } from "utils/statusColors";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";

export default function TasksColorComponent({ readOnly, user }) {
  const classes = useStyles();
  const { updateUser } = useUser();
  const navigate = useNavigate()

  const TaskColorValidationSchema = Yup.object().shape({
    bgColor: Yup.string(),
    txtColor: Yup.string(),
  });

  React.useEffect(() => {
    if (user?.txtColor && user?.bgColor) {
      formik.setValues({
        bgColor: user.bgColor,
        txtColor: user.txtColor,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formik = useFormik({
    initialValues: {
      bgColor: "lightgray",
      txtColor: "black",
    },
    validationSchema: TaskColorValidationSchema,
    onSubmit: async (values) => {
      if (user?.uuid) {
        try {
          await updateUser(
            values,
            window.location.pathname === "/app/profile" ? null : user.uuid
          );
          Swal.fire({
            toast: true,
            timer: 4000,
            position: "top-right",
            title: 'Color Updated Successfully!',
            showConfirmButton: false,
            icon: "success",
            timerProgressBar: true,
          });
          navigate('/app/users')
        } catch (error) {
          if (error?.response?.data?.errors) {
            Object.keys(error.response.data.errors).forEach((field) => {
              if (error.response.data.errors[field][0] !== undefined)
                formik.setFieldError(
                  field,
                  error.response.data.errors[field][0]
                );
            });
          }
        }
      }
    },
  });

  const onChangeColor = debounce((color, type) => {
    formik.setFieldValue(type, color);
  }, 300);

  return (
    <Box>
      <Typography variant="h6" fontWeight="bold" color="primary" sx={{ mb: 2 }}>
        Change tasks colors
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
            <Box>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="secondary"
                sx={{ mb: 2 }}
              >
                Background color
              </Typography>
              <Box>
                <FormControl sx={{ mb: 0 }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    sx={{ display: "flex", flexDirection: "row", mb: 0 }}
                  >
                    {tasksColors.map((defaultColor) => (
                      <FormControlLabel
                        key={defaultColor}
                        value={defaultColor}
                        disabled={readOnly}
                        label=""
                        checked={false}
                        onChange={(e) =>
                          formik.setFieldValue("bgColor", e.target.value)
                        }
                        control={
                          <Radio
                            icon={<CircleIcon sx={{ color: defaultColor }} />}
                            checkedIcon={
                              <RadioButtonCheckedIcon
                                sx={{ color: defaultColor }}
                              />
                            }
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <Typography mb={2} variant="body2">
                  or choose a custome one
                </Typography>
                <FormField
                  type="color"
                  disabled={readOnly}
                  value={formik.values.bgColor}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => onChangeColor(e.target.value, "bgColor")}
                  error={
                    formik.touched.bgColor && Boolean(formik.errors.bgColor)
                  }
                  helperText={formik.touched.bgColor && formik.errors.bgColor}
                />
              </Box>
            </Box>
            <Divider />
            <Box mt={2}>
              <Typography
                variant="body1"
                fontWeight="bold"
                color="secondary"
                sx={{ mb: 2 }}
              >
                Text color
              </Typography>
              <Box>
                <FormControl sx={{ mb: 0 }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    sx={{ display: "flex", flexDirection: "row", mb: 0 }}
                  >
                    {tasksColors.map((defaultColor) => (
                      <FormControlLabel
                        key={defaultColor}
                        value={defaultColor}
                        disabled={readOnly}
                        onChange={(e) =>
                          formik.setFieldValue("txtColor", e.target.value)
                        }
                        label=""
                        checked={false}
                        control={
                          <Radio
                            icon={<CircleIcon sx={{ color: defaultColor }} />}
                            checkedIcon={
                              <RadioButtonCheckedIcon
                                sx={{ color: defaultColor }}
                              />
                            }
                          />
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <Typography mb={2} variant="body2">
                  or choose a custome one
                </Typography>
                <FormField
                  type="color"
                  disabled={readOnly}
                  value={formik.values.txtColor}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => onChangeColor(e.target.value, "txtColor")}
                  error={
                    formik.touched.txtColor && Boolean(formik.errors.txtColor)
                  }
                  helperText={formik.touched.txtColor && formik.errors.txtColor}
                />
              </Box>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            variant="body1"
            fontWeight="bold"
            color="secondary"
            sx={{ mb: 2 }}
          >
            Preview
          </Typography>
          <Chip
            label={`${user?.first_name} ${user?.last_name}`}
            sx={{
              color: formik.values.txtColor,
              bgcolor: formik.values.bgColor,
              height: 50,
              minWidth: 180,
              borderRadius: 100,
              fontSize: 20,
            }}
          />
        </Grid>
        {!readOnly && (
          <Grid item xs={12} md={6}>
            <Button
              onClick={formik.handleSubmit}
              variant="contained"
              color="primary"
            >
              Update colors
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

TasksColorComponent.defaultProps = {
  readOnly: false
}
