import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import brand from "theme/brand";
import {
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import useStyles from "../main-jss";
import PasswordTextField from "components/PasswordTextField";
import useAuth from "hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function ResetPassword() {
  const classes = useStyles();
  const { resetPasswordReq } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      conformPassword: "",
      termsAgree: true,
    },
    onSubmit: async (values) => {
      setSubmitting(true);
      setLoading(true);
      resetPasswordReq(values.password, values.conformPassword, (success, error) => {
        setLoading(false);       
        if (!error) {
          Swal.fire({
            toast: true,
            icon: "success",
            title: success.data.message || 'Reset Password link sent',
            position: "top-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          navigate("/")
        } else {
          setSubmitting(false);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            toast: true,
            position: 'top-right',
            timer: 3000,
            title: error?.response.data.message || 'Something went wrong!',
            showConfirmButton: false
          });
        }
      });
    },
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <Paper className={classes.paperWrap}>
            <div className={classes.topBar}>
              <NavLink to="/" className={classes.brand}>
                <img src={brand.logo} alt={brand.name} />
              </NavLink>
            </div>
            <Typography variant="h5" className={classes.title} gutterBottom>
              Reset Password
            </Typography>
            {/* TODO: socmedLogin */}
            <section className={classes.formWrap}>
              <form onSubmit={formik.handleSubmit}>
                <Box pt={2} pb={2}>
                  <PasswordTextField
                    fullWidth={true}
                    withPreview
                    size="medium"
                    color="secondary"
                    name="password"
                    variant="outlined"
                    placeholder={t("auth:password_placeholder")}
                    label="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={!!error}
                    helperText={error}
                  />
                </Box>
                <Box pt={2} pb={2}>
                  <PasswordTextField
                    fullWidth={true}
                    withPreview
                    size="medium"
                    color="secondary"
                    name="conformPassword"
                    variant="outlined"
                    placeholder={t("auth:password_placeholder")}
                    label="Confirm Password"
                    value={formik.values.conformPassword}
                    onChange={formik.handleChange}
                    error={!!error}
                    helperText={error}
                  />
                </Box>
                <div className={classes.btnArea}>
                  <Button
                    disabled={submitting}
                    variant="contained"
                    fullWidth
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    Change Password
                    {loading && (
                      <Box pl={4}>
                        <CircularProgress color="secondary" size={22} />{" "}
                      </Box>
                    )}
                  </Button>
                </div>
              </form>
            </section>
          </Paper>
        </div>
      </div>
    </div>
  );
}
