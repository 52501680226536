import React, { useEffect } from "react";
import NotFound from "pages/Errors/NotFound";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import Layout from "../Components/Layout";
import TaskQueue from "./TaskQueue";
import Members from "./Members/Index";
import ViewMembership from "./Members/View";
import CreateMember from "./Members/Create";
import Checkout from "./Members/Checkout";
import Contractors from "./Contractors/Index";
import CreateContractor from "./Contractors/Create";
import CreateInvoice from "./Invoices/Create";
import ViewContractor from "./Contractors/View";
import Vendors from "./Vendors/Index";
import CreateVendor from "./Vendors/Create";
import UpdateVendor from "./Vendors/Update";
import Settings from "./Settings";
import JobsView from "./Jobs/View";
import Jobs from "./Jobs/Index";
import Invoices from "./Invoices/Index";
import Users from "./Users/Index";
import Reports from "./Reports/Index";
import useUtils from "hooks/useUtils";
import CreateUser from "./Users/Create";
import ViewUser from "./Users/View";
import ViewProfile from "./Profile/View";
import InvoiceBatchProcess from "./Batch/Process";
import ViewInvoice from "./Invoices/View";
import InvoiceBatches from "./Batch/Index";
import Roles from "./Roles";
import QBEOauth from "./QBEOauth";
import Ringcentral from "./Ringcentral/View";
import Paymentsvalue from "./Payments/Index";
import Callback from "Callback";
import ContactWidget from "components/Widgets/ContactsCall";
import useAccessControl from "hooks/useAccessControl";
import { useSelector } from "react-redux";
import Dashboardacc from "./Dashboardacc";
import PdeUi from "./Batch/Process/components/BillingTable/PdfUi";
import WelcomePage from "pages/WelcomePage";
import CreateCheck from "./Invoices/CreateCheck";
import CheckUpdate from "./Invoices/Index/CheckUpdate";
import InportContractor from "./Contractors/Index/components/InportContractor";
import ViewInvConreactor from "./Contractors/View/viewInvConreactor";

export default function App() {
  const { getDefaults } = useUtils();
  const user = useSelector((store) => store.auth);
  const {
    canReadUser,
    canReadJob,
    canReadInvoice,
    canReadContractor,
    canReadVendor,
    canReadRole,
    canReadDashborad,
    canReadTask,
    canReadPayment,
    canReadMember,
    canReadSetting,
    canReadConnect
  } = useAccessControl();

  useEffect(() => {
    getDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <Routes>
        {/* {canReadDashborad() && (
          <Route index element={<Dashboard />}/>
        )} */}
        {/* <Route index element={<WelcomePage />}/> */}

        {(() => {
          switch (true) {
            case (user.data.role === "ACCOUNTING" && canReadDashborad()):
              return <Route index element={<Dashboardacc />} />;
            case (user.data.role === "EXECUTIVE" || user.data.role_id === "EXECUTIVE"):
              return <Route index element={<Dashboard />} />;
            case (user.data.role === "SYSTEM_ADMINISTRATOR"):
              return <Route path="/users" element={<Users />} />;
            case (user.data.role === "CUSTOMER_SERVICE"):
              return <Route index element={<Dashboard />} />;
            // Add other cases here...
            default:
              return null;
          }
        })()}

        {canReadTask() && (
          <Route path="/task-queue" element={<TaskQueue />} />
        )}
        {canReadMember() && (
          <Route path="/members" element={<Members />} />
        )}
        <Route path="/members/create" element={<CreateMember />} />
        <Route path="/members/checkout/:order_uuid" element={<Checkout />} />
        <Route path="/members/:uuid" element={<ViewMembership />} />
        {canReadContractor() && (
          <Route path="/contractors" element={<Contractors />} />
        )}
        <Route path="/contractors/:id" element={<ViewContractor />} />
        <Route path="/contractors/create" element={<CreateContractor />} />
        {canReadVendor() && (
          <Route path="/vendors" element={<Vendors />} />
        )}
        <Route path="/vendors/create" element={<CreateVendor />} />
        <Route path="/vendors/:id" element={<UpdateVendor />} />
        {canReadJob() && (
          <Route path="/jobs" element={<Jobs />} />
        )}
        <Route path="/jobs/:job_uuid" element={<JobsView />} />
        {canReadInvoice() && (
          <Route path="/invoices" element={<Invoices />} />
        )}
        <Route path="/invoices/create" element={<CreateInvoice />} />
        <Route path="/invoices/checkUpdate/:id" element={<CheckUpdate />} />
        <Route path="/importContractor" element={<InportContractor />} />
        <Route path="/viewInvContractorHoc/:id/:conid" element={<ViewInvConreactor />} />
        <Route path="/invoices/createCheck/:order_uuid" element={<CreateCheck />} />
        <Route path="/invoices/batch/:batch_id/process" element={<InvoiceBatchProcess />} />
        <Route path="/invoices/batches" element={<InvoiceBatches />} />
        <Route path="/invoices/:id" element={<ViewInvoice />} />
        {canReadPayment() && (
          <Route path="/payments" element={<Paymentsvalue />} />
        )}
        {canReadUser() && (
          <Route path="/users" element={<Users />} />
        )}
        <Route path="/profile" element={<ViewProfile />} />
        <Route path="/users/create" element={<CreateUser />} />
        <Route path="/users/:uuid" element={<ViewUser />} />
        {canReadRole() && (
          <Route path="/roles" element={<Roles />} />
        )}
        <Route path="/reports" element={<Reports />} />
        {canReadSetting() && (
          <Route path="/settings" element={<Settings />} />
        )}
        {canReadConnect() && (
          <Route path="/Connect" element={<Ringcentral />} />
        )}
        <Route path="/quickbooks/oauth/callback" element={<QBEOauth />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/contractcall" element={<ContactWidget />} />

        <Route path="/pdfUi" element={<PdeUi />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}
