import axios from "utils/axios";
import { SEARCH_CONTRACTOR_ROUTE } from "./routes";

// const SEARCH_CONTRACTOR_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;

const GET_CONTRACTOR_BY_EMAIL = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/search-by-email`;
const CREATE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
const STATUS_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/change-status`;
const GET_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
const UPDATE_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors`;
const POST_CONTRACTOR = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/import-contractor`;
const POST_CONTRACTOR_INV = `${process.env.REACT_APP_API_DOMAIN}/api/v1/contractors/invoice`;


export const searchContractorsRequest = (search, limit = 10) =>
  axios.get(SEARCH_CONTRACTOR_ROUTE, { params: { search, limit } });

export const getContractorsRequest = (search) =>
  axios.get(SEARCH_CONTRACTOR_ROUTE, { params: search });

export const getContractorByEmail = (email) =>
  axios.post(GET_CONTRACTOR_BY_EMAIL, { email });

export const createContractor = (data) =>
  axios.post(CREATE_CONTRACTOR, { ...data });

export const statusContractor = (contractorId) =>
  axios.get(`${STATUS_CONTRACTOR}/${contractorId}`);

export const getContractor = (id) => axios.get(`${GET_CONTRACTOR}/${id}`);

export const getContractorsInvRequest = (id,conid) =>// /id?id=23

axios.post(`${POST_CONTRACTOR_INV}/id`, {
  con_inv_id: conid ||  "",
  contractor_id: id ||  ""
})

export const updateContractor = (id, data) =>
  axios.post(`${UPDATE_CONTRACTOR}/${id}`, { ...data });

export const csvUploadContractor = (data) =>
  axios.post(POST_CONTRACTOR, data, {
    headers: { 'Content-Type': 'application/json' }
  });
