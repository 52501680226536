import React, { useEffect, useState } from 'react'
import ContentBlock from "components/ContentBlock";
import { Table } from '@mui/material';
import { getContractorsInvRequest } from 'api/contractors';
import { useParams } from 'react-router-dom';
import { images } from "utils/Images.js";
import "../../../../../src/components/Callincoming/index.css"
const ViewInvConreactor = () => {
    const { id, conid } = useParams();
    const [invViewData, setInvViewData] = useState([])
    useEffect(() => {
        getContractorsInvRequest(conid, id)
            .then((resp) => {
                if (resp.data) {
                    setInvViewData(resp.data)
                }
            })
    }, [id]);

    return (
        <>
            <ContentBlock title="View Contractor Invoice ">
                {invViewData?.invoice?.map((invView) => {
                    return (
                        <>
                            <Table>
                                <tr>
                                    <td style={{ textAlign: "end" }}>
                                        <table style={{ width: "100%" }}>
                                            <tr >
                                                <td  >
                                                    <table className='invoices_table'>
                                                        <tr>
                                                            <td className='invoices_td' >INVOICE NO : {invView?.hoc_inv_num}</td>
                                                            <td className='invoices_td' >TRACKING NO : {invView?.tracking || "NA"}</td>
                                                            <td className='invoices_td' >CLUB NO.</td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table className='serivecs'>
                                                        <tr>
                                                            <td className='serivecs_td' >
                                                                <table>
                                                                    <tr><th>SERIVCE PERFORMANCE FOR:</th></tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span>MEMBER: </span>
                                                                            <span>{invView?.mem_name}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span>STREET: </span>
                                                                            <span>{invView?.mem_street}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span>CITY: </span>
                                                                            <span>{invView?.mem_city}</span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <span>DATE: </span>
                                                                            <span>{invView?.inv_date}</span>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td style={{ width: "50%", textAlign: "left" }}>
                                                                <table>
                                                                    <tr>
                                                                        <th>SERIVCE PERFORMANCE FOR: </th>
                                                                        <td> {invView?.con_lic}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>{invView?.con_biz}</span></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td> <span>{invView?.con_addy1}, {invView?.con_addy2}</span> </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><span>{invView?.con_csz}</span> </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table className='tableRow' >
                                            <tr style={{ backgroundColor: "#116089", color: "#fff" }}>
                                                <th>MATERIAL</th>
                                                <th>AMOUNT</th>
                                                <th>DESCRIPTION OF WORK / LABOR</th>
                                                <th>AMOUNT</th>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_1 || "NA"}</td>
                                                <td>{invView?.material_amt_1 || "NA"}</td>
                                                <td>{invView?.labor_1 || "NA"} </td>
                                                <td>{invView?.labor_amt_1 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_2 || "NA"}</td>
                                                <td>{invView?.material_amt_2 || "NA"}</td>
                                                <td>{invView?.labor_2 || "NA"}</td>
                                                <td>{invView?.labor_amt_2 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_3 || "NA"}</td>
                                                <td>{invView?.material_amt_3 || "NA"}</td>
                                                <td>{invView?.labor_3 || "NA"}</td>
                                                <td>{invView?.labor_amt_3 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_4 || "NA"}</td>
                                                <td>{invView?.material_amt_4 || "NA"}</td>
                                                <td>{invView?.labor_4 || "NA"}</td>
                                                <td>{invView?.labor_amt_4 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_5 || "NA"}</td>
                                                <td>{invView?.material_amt_5 || "NA"}</td>
                                                <td>{invView?.labor_5 || "NA"}</td>
                                                <td>{invView?.labor_amt_5 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_6 || "NA"}</td>
                                                <td>{invView?.material_amt_6 || "NA"}</td>
                                                <td>{invView?.labor_6 || "NA"}</td>
                                                <td>{invView?.labor_amt_6 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_7 || "NA"}</td>
                                                <td>{invView?.material_amt_7 || "NA"}</td>
                                                <td>{invView?.labor_7 || "NA"}</td>
                                                <td>{invView?.labor_amt_7 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_8 || "NA"}</td>
                                                <td>{invView?.material_amt_8 || "NA"}</td>
                                                <td>{invView?.labor_8 || "NA"}</td>
                                                <td>{invView?.labor_amt_1 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_9 || "NA"}</td>
                                                <td>{invView?.material_amt_9 || "NA"} </td>
                                                <td>{invView?.labor_9 || "NA"}</td>
                                                <td>{invView?.labor_amt_9 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_10 || "NA"}</td>
                                                <td>{invView?.material_amt_10 || "NA"}</td>
                                                <td>{invView?.labor_10 || "NA"}</td>
                                                <td>{invView?.labor_amt_10 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_11 || "NA"}</td>
                                                <td>{invView?.material_amt_11 || "NA"}</td>
                                                <td>{invView?.labor_12 || "NA"}</td>
                                                <td>{invView?.labor_amt_11 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_12 || "NA"}</td>
                                                <td>{invView?.material_amt_12 || "NA"}</td>
                                                <td>{invView?.labor_12 || "NA"}</td>
                                                <td>{invView?.labor_amt_12 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_13 || "NA"}</td>
                                                <td>{invView?.material_amt_13 || "NA"}</td>
                                                <td>{invView?.labor_13 || "NA"}</td>
                                                <td>{invView?.labor_amt_13 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_14 || "NA"}</td>
                                                <td>{invView?.material_amt_14 || "NA"}</td>
                                                <td>{invView?.labor_14 || "NA"}</td>
                                                <td>{invView?.labor_amt_14 || "NA"}</td>
                                            </tr>
                                            <tr>
                                                <td>{invView?.material_15 || "NA"}</td>
                                                <td>{invView?.material_amt_15 || "NA"}</td>
                                                <td>{invView?.labor_15 || "NA"}</td>
                                                <td>{invView?.labor_amt_15 || "NA"}</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <table style={{ width: "100%" }}>
                                    <tr>
                                        <th className='remark' >REMARK: {invView?.remarks}</th>
                                        <td style={{ width: "25%" }}>
                                            <table style={{ marginRight: "15px" }}>
                                                <tr>
                                                    <td>
                                                        <table className='arrived_table' >
                                                            <tr>
                                                                <th>ARRIVED</th>
                                                                <th>DEPARTED</th>
                                                                <th>TOTAL TIME</th>
                                                            </tr>
                                                            <tr>
                                                                <td>{invView?.time_arrive || "NA"}</td>
                                                                <td>{invView?.time_depart || "NA"}</td>
                                                                <td>{invView?.time_total || "NA"}</td>
                                                            </tr>
                                                        </table>
                                                        <td className='table_img'>
                                                            <img className='' src={images.loginLogo.url} alt="logo" />
                                                        </td>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <img src="hoc-logo.png" alt="" />
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td className='amountTable'>
                                            <table >
                                                <tr>
                                                    <th >TOTAL LABOR</th>
                                                    <td >{invView?.inv_total_labor}</td>
                                                </tr>
                                                <tr>
                                                    <th > TOTAL MATRERIAL</th>
                                                    <td >{invView?.inv_total_material}</td>
                                                </tr>
                                                <tr>
                                                    <th > NATE SALE </th>
                                                    <td >{invView?.inv_net_sale}</td>
                                                </tr>
                                                <tr>
                                                    <th > Rate 10.35% TAX</th>
                                                    <td >{invView?.tax}</td>
                                                </tr>
                                                <tr>
                                                    <th className='total-amt' ><span style={{ color: "white", fontSize: "17px", fontWeight: "500" }}>TOTAL AMOUNT $</span>  </th>
                                                    <td className='total-amt'><span style={{ color: "white", fontSize: "17px", fontWeight: "500" }}>{invView?.total}</span></td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </Table>
                        </>
                    )
                })}

            </ContentBlock>
        </>


    )
}

export default ViewInvConreactor
