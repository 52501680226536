import React from "react";
import { TableRow, TableCell, IconButton, Menu } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import Mail from "@mui/icons-material/Mail";
import MessageCard from "components/Widgets/Contacts/MessageCard";
import { sendMsgRequest } from "api/connect";
import Toast from "components/Toast";
import useSystem from "../../../WebR/hooks/useSystem";
import Swal from "sweetalert2";

const ContractorRow = ({ data, deleteContractor }) => {

  const [isMessageCardVisible, setMessageCardVisibility] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const opend = Boolean(isMessageCardVisible);
  const { call } = useSystem();



  const handleCloseMsg = () => {
    setMessageCardVisibility(null);
  };

  const handleShowMessageCard = (event) => {
    setMessageCardVisibility(event.currentTarget);
  };

  const handleSendMessage = async (message, CellPhone) => {
    let convertedNumber = CellPhone.replace(/[\s\(\)-]/g, "");
    try {
      setLoading(true);
      sendMsgRequest({
        to: convertedNumber,
        text: message,
      })
        .then((res) => {
          setMessageCardVisibility(null);
          Toast.fire({
            position: "bottom-end",
            icon: "success",
            title: "Message Sent Successfully!",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      console.error('Error sending message:', error.message);
      handleCloseMsg()
    }

  };


  const callContact = (member, number, nameValue) => {
    if (localStorage.getItem("status") === "offline") {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-right",
        text: "You Are Not Online!",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
    } else if (localStorage.getItem("status") === "online") {
      // const numericPart = number.replace(/^\+1 /, "");
      const numericPart = number.replace(/[^\d]/g, '');
      console.log(numericPart, 'numericPart');
      call('+' + numericPart, nameValue);
    }
  };




  return (
    <TableRow >
      <TableCell component="th" scope="row" width="5%">
        {data?.id}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        {data?.name}
      </TableCell>
      <TableCell component="th" scope="row" width="20%">
        {data?.cell_phone}
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        <IconButton>
          <Mail onClick={handleShowMessageCard} />
          <Menu
            id={`menu-${data?.id}`}
            anchorEl={isMessageCardVisible}
            open={opend}
            onClose={handleCloseMsg}
          >
            <MessageCard
              onSend={handleSendMessage}
              CellPhone={data?.cell_phone}
              loading={loading}
            />
          </Menu>
        </IconButton>
      </TableCell>
      <TableCell component="th" scope="row" width="25%">
        <IconButton>
          <PhoneIcon
            onClick={() => callContact(data?.id, data?.cell_phone, data?.name, "ConferanceCall")}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default ContractorRow;

