import React, { useState } from "react";
import Button from "@mui/material/Button";
import '../../utils/sweetalert.css'
import {
  Grid,
  MenuItem,
  Typography,
  Box,
} from "@mui/material";
import ".././../utils/sweetalert.css"
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";
import FormField from "components/FormField";
import { statuses } from "utils/jobStatuses";
import ApiSelect from "components/ApiSelect";
import { getUsersRequest } from "api/users";
import { createJobRequest } from "api/jobs";
import QModal from "components/QModal";
import JobDetails from "./components/JobDetails";
import Members from "./components/Members";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  modalHeader: {
    borderBottom: "solid 1px #ddd",
  },
  modalTitle: {
    padding: 0,
  },
  modalSubtitle: {
    color: "gray",
  },
  dialogContent: {
    overflow: "auto",
  },
  actionsLabel: {
    color: "gray",
  },
  whited: {
    "& div": {
      color: "white",
    },
  },
  COMPLETED: { background: statuses.COMPLETED },
  DISPATCH: { background: statuses.DISPATCH },
  ESTIMATE: { background: statuses.ESTIMATE },
  SIGNED: { background: statuses.SIGNED },
  CANCELLED: { background: statuses.CANCELLED },
  OPEN: { background: statuses.OPEN },
  NEW: { background: statuses.OPEN },
}));

const CreateJobModal = ({ open, handleClose, fetchJons }) => {

  const theme = useTheme();
  const styles = {
    swaltext: {
      fontFamily: ['Open Sans', 'sans serif']
    }
  };
  const classes = useStyles();
  const [membershipId, setMembershipId] = useState(null);

  const updateMembershipId = (id) => {
    setMembershipId(id);
  };

  const [state, _setState] = useState({
    id: null,
    status: "NEW",
    contact_id: null,
    property_id: null,
    category_id: null,
    code_id: null,
    description: "",
    contact: {},
    property: {},
    assigned: 0,
    estimates_request: [],
  });

  const setState = (values) => _setState({ ...state, ...values });


  const handleSubmit = (e) => {
    e.preventDefault();
    fetchJons()
    createJobRequest({
      membership_id: membershipId,
      status: state.status,
      contact_id: state.contact_id,
      property_id: state.property_id,
      category_id: state.category_id,
      code_id: state.code_id,
      description: state.description,
      assigned: state.assigned
    }).then((res) => {
      setState(res.data);
      fetchJons()
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: 'Job Request Submit Successfully!',
        showConfirmButton: false,
        icon: "success",
        timerProgressBar: true,
        confirmButtonColor: theme.palette.primary.main,
      });

      handleClose();
    });
  };

  return (
    <QModal
      dialogProps={{
        open: true,
        title: `Job request`,
        subtitle: "",
      }}
      handleClose={handleClose}
    >
      <>
        <Grid container spacing={2}>
          <Grid item sm={8} md={9}>
            <form onSubmit={handleSubmit}>
              <Members
                contact={state.contact}
                property={state.property}
                property_id={state.property_id}
                setState={setState}
                setMembershipId={updateMembershipId}
              />
              <JobDetails
                category_id={state.category_id}
                code_id={state.code_id}
                description={state.description}
                setJob={setState}
              />
              <Box textAlign="center" p={3}>
                <Button
                  role="submit"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit Job Request
                </Button>
              </Box>
            </form>
          </Grid>
          <Grid item sm={4} md={3}>
            <Box mt={2}>
              <Typography
                variant="subtitle2"
                className={classes.actionsLabel}
              >
                Status
              </Typography>
              <FormField
                select
                value={state.status}
                className={classes.whited}
                classes={{ root: classes[state.status] }}
                onChange={(e) => setState({ status: e.target.value })}
              >
                {state.status === "NEW" && (
                  <MenuItem value={"NEW"}>NEW</MenuItem>
                )}
              </FormField>
            </Box>
            <Box mt={2}>
              <Typography
                variant="subtitle2"
                className={classes.actionsLabel}
              >
                Assigned To
              </Typography>
              <ApiSelect
                inputProps={{
                  value: state.assigned,
                  onChange: (e) => setState({ assigned: e.target.value }),
                }}
                source={getUsersRequest}
                reducer="users"
                itemLabel={"name"}
                showEmpty
              />
            </Box>
          </Grid>
        </Grid>
      </>
    </QModal>
  );
};

export default CreateJobModal;
