import { Box, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import InvoiceRow from './InvoiceRow'
import useInvoices from 'hooks/useInvoices';
import TableFooterWithPagination from 'components/TableFooterWithPagination';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useMemo, useState } from 'react';

const InvoiceData = () => {
  const {
    invoicesReducer: { search, results, meta, loading },
    searchInvoices,
    changePage,
    changePerPage,
    getInvoices,
  } = useInvoices();

  const [orderBy, setOrderBy] = useState('asc');
  const [order, setOrder] = useState('id');

  const handleSort = (column) => {
    if (orderBy === column) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else {
      setOrderBy(column);
      setOrder('asc');
    }
  };

  const sortedData = useMemo(() => {
    if (!orderBy) return results;
    return results.sort((a, b) => {
      if (orderBy === 'date') {
        let dateA, dateB;
        if (a.date && b.date) {
          dateA = Date.parse(a.date.replace(/-/g, '/'));
          dateB = Date.parse(b.date.replace(/-/g, '/'));
        } else {
          return a.date ? -1 : 1;
        }
        if (order === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      } else {
        if (order === 'asc') {
          return a[orderBy] > b[orderBy] ? 1 : -1;
        } else {
          return a[orderBy] < b[orderBy] ? 1 : -1;
        }
      }
    });
  }, [order, orderBy, results]);

  const handleSearch = (value) => {
    searchInvoices({ search: value, page: meta.current_page, per_page: meta.per_page });
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getInvoices({ search, page: newPage + 1, per_page: meta.per_page });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getInvoices({
      search,
      page: meta.current_page,
      per_page: parseInt(event.target.value)
    });
  };

  const handleFilter = (values) => {
    getInvoices({
      filter: values,
      search,
      page: meta.current_page,
      per_page: meta.per_page
    });
  };

  return (
    <>
      {loading ? (
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <th onClick={() => handleSort('id')}>ID {order.id === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</th>
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Amount</TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  <th onClick={() => handleSort('payment_method')}>Payment Type {order.payment_method === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</th>
                </TableCell>
                <TableCell>
                  <th onClick={() => handleSort('created_at')}>Date {order.created_at === 'asc' ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</th>
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap", fontWeight: 'bold'  }}>QBE Sent</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell sx={{ paddingRight: 15, fontWeight: 'bold' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results.map((invoice) => (
                <InvoiceRow data={invoice} key={invoice.id} />
              ))}
              {!results.length && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No data
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            {results.length > 0 && (
              <TableFooterWithPagination
                count={meta.total}
                rowsPerPage={meta.per_page}
                page={meta.current_page - 1}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </Table>
        </Box>
      )}
    </>
  )
}
export default InvoiceData;