import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Tab, Button, CircularProgress, Alert } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useLocation, useParams } from "react-router-dom";
import ContentBlock from "components/ContentBlock";
import NewJobRequest from "components/JobRequestModal";
import useApplication from "hooks/useApplication";
import useAccessControl from "hooks/useAccessControl";
import useMembership from "hooks/useMembership";
import Collapse from "components/Collapse";
import { contact, property } from "components/MembershipForm/formConfig";
import JobSheetModal from "components/JobSheetModal";
import Notes from "components/Notes";
import FileManager from 'components/FileManager';
import MemberApplicationApproval from "./components/MemberApplicationApproval";
import MembershipDetails from "./components/MembershipDetails";
import MembershipJobs from "./components/MembershipJobs";
import PropertiesList from "./components/PropertiesList";
import Invoices from "./components/Invoices";
import Contractors from './components/Contractors';

export default function View() {
  const { setPage } = useApplication();
  const { viewMembership } = useMembership();
  const { canReadJob, canReadInvoice,canCreateJob } = useAccessControl();
  const { uuid } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState("0");
  const [open, setOpen] = useState(false);
  const [viewJob, setViewJob] = useState(false);
  const [formData, setFormData] = useState({
    jobs: [],
  });

  const setForm = (values) => setFormData({ ...formData, ...values });
  const getMembership = () => {
    viewMembership(uuid)
      .then(({ data }) => {
        setFormData(data);
      })
      .catch((_e) => {
        console.log("Membership not found");
      });
  };

  useEffect(() => {
    if (formData.membership)
      setPage({
        title: `No. ${formData.membership.id} - ${formData.members[0].first_name} ${formData.members[0].last_name}`,
        path: "/app/members",
      });
    // eslint-disable-next-line
  }, [formData.membership]);

  useEffect(() => {
    if (!open) {
      getMembership();
      setViewJob(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if(location?.state?.notes) {
      setTabValue("2");
    }
  }, [location]);

  const addMember = () => {
    const _members = formData.members;
    _members.push({ ...contact });
    setForm({ members: _members });
  };

  const addProperty = () => {
    const _properties = formData.properties;
    _properties.push({ ...property });
    setForm({ properties: _properties });
  };

  const handleMembershipChangeState = (newState, reason) => {
    const mbp = { ...formData.membership };
    mbp.state = newState;
    if (reason) {
      mbp.cancel_reason = reason;
    }
    setForm({ membership: mbp });
  };

  const handleViewJob = (job) => {
    setViewJob(job);
  };

  return (
    <ContentBlock
      title={
        !formData.membership
          ? "Loading..."
          : `No. ${formData.membership.id} - ${formData.members[0].first_name} ${formData.members[0].last_name}`
      }
      desc="Member Profile"
      icon="ion-ios-contacts-outline"
      rightActions={
        canCreateJob() &&
        formData.membership &&
        formData.membership.state === "MEMBERSHIP" && (
          <Button
            onClick={() => setOpen(true)}
            color="primary"
            size="small"
            variant="contained"
          >
            Add Job Request
          </Button>
        )
      }
    >
      {!formData.membership && (
        <Box display="flex" justifyContent="center" alignItems="center" p={30}>
          <CircularProgress />
        </Box>
      )}
      {formData.membership && (
        <>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
                <Tab
                  label={t("memberDirectory:membership_details")}
                  value="0"
                />
                <Tab label={t("memberDirectory:properties")} value="1" />
                <Tab label={t("memberDirectory:notes")} value="2" />
                <Tab label={t("memberDirectory:contractors")} value="3" />
                <Tab label="Files" value="4" />
                {formData.membership.state === "APPLICATION" && (
                  <Box display="flex" flexGrow={1} justifyContent={"flex-end"}>
                    <MemberApplicationApproval
                      membership_id={formData.id}
                      changeMembershipState={(val, reason) =>
                        handleMembershipChangeState(val, reason)
                      }
                    />
                  </Box>
                )}
              </TabList>
            </Box>
            <TabPanel value="0">
              {formData.membership.state === "CANCELED" && (
                <Alert sx={{ mb: 2 }} severity="error">
                  Member application cancelled with the following reason: "
                  {formData.membership.cancel_reason}"
                </Alert>
              )}
              <MembershipDetails
                setForm={setForm}
                form={formData}
                addMember={addMember}
              />
              {canReadJob() && (
                <Collapse pse title="JOBS">
                  <MembershipJobs
                    refresh={getMembership}
                    jobs={formData.jobs}
                    openView={(job) => handleViewJob(job)}
                  />
                </Collapse>
              )}
              {canReadInvoice() && (
                <Collapse title="INVOICES">
                  <Invoices invoices={formData.invoices} />
                </Collapse>
              )}
              {/* <Collapse title="PAYMENTS">
                <Payments invoices={formData.invoices} />
              </Collapse> */}
            </TabPanel>
            <TabPanel value="1">
              <PropertiesList
                form={formData}
                setForm={setForm}
                addProperty={addProperty}
              />
            </TabPanel>
            <TabPanel value="2">
              <Notes
                notableType="Membership"
                notableId={formData.membership.id}
              />
            </TabPanel>
            <TabPanel value="3"><Contractors membershipId={formData.membership.id}/></TabPanel>
            <TabPanel value="4">
              <FileManager
                fileable_id={formData.membership.id}
                fileable_type="membership"
              />
            </TabPanel>
          </TabContext>
          {open && (
            <NewJobRequest
              membership_id={formData.membership.id}
              open={open}
              handleClose={() => setOpen(false)}
            />
          )}
          {viewJob && (
            <JobSheetModal
              viewJob={viewJob}
              open
              membership_id={formData.membership.id}
              handleClose={() => {
                setViewJob(false);
                getMembership();
              }}
            />
          )}
        </>
      )}
    </ContentBlock>
  );
}
