import { Button, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const CheckRow = ({ check, deleteMemeberHandler }) => {
    const navigate = useNavigate();
    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row">
                    {check.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {check.first_name}
                </TableCell>
                <TableCell component="th" scope="row">
                    {check.last_name}
                </TableCell>
                <TableCell component="th" scope="row">
                    {check.check_number}
                </TableCell>
                <TableCell component="th" scope="row">
                    {check.numeric_amount}
                </TableCell>
                <TableCell component="th" scope="row">
                    {check.bank_name}
                </TableCell>
                <TableCell component="th" scope="row">
                    {check.account_number}
                </TableCell>
                <TableCell component="th" scope="row" align="right" width="20%">
                    <div style={{ display: "flex", width: "100%" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ ml: 2 }}
                            size="small"
                            onClick={() => navigate(`/app/invoices/checkUpdate/${check.id}`)}
                        >
                            VIEW
                        </Button>
                        {/* <Button
                            variant="contained"
                            color="error"
                            sx={{ ml: 2 }}
                            size="small"
                            onClick={() => deleteMemeberHandler(check.id)}
                        >
                            DELETE
                        </Button> */}
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}
export default CheckRow;