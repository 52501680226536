import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress
} from "@mui/material";
import useMembership from "hooks/useMembership";
import MemberRow from "./MemberRow";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import Swal from "sweetalert2";
import { deleteMemberRequest } from "api/membership";
import CallPending from "WebR/components/CallPending";

export default function Member() {
  const navigate = useNavigate();
  const {
    search,
    getMemberships,
    membershipReducer: { results, loading, query, meta },
    changePage,
    changePerPage,
  } = useMembership();

  useEffect(() => {
    search(query);
  }, []);

  const handleSearch = (value) => {
    search(value);
  };

  const handleChangePage = (_event, newPage) => {
    changePage(newPage);
    getMemberships({
      search: query,
      page: newPage + 1,
      per_page: meta.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getMemberships({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };


  const deleteMemeberHandler =  (membership_id) => {
    try {
      Swal.fire({
        title: "Cancel member?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        customClass: {
          title: "swal-text",
          popup: "swal-text"
        },
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteMemberRequest(membership_id)
            .then(() => {
              search();
              Swal.fire({
                toast:true,
                timer:3000,
                position:"top-right",
                title:'Member Canceled Successfully!',
                showConfirmButton:false,
                icon:"success"
              })
            })
            .catch((e) => {});
        }       
        navigate('/app/members')
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <>
          <div style={{ overflow: "auto" }}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell> Action </TableCell>
                  {/* <TableCell align="right"></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {results?.map((member) => (
                  <MemberRow
                    key={member.membership_id}
                    data={member}
                    deleteMemeberHandler={deleteMemeberHandler}
                    loading={loading}
                  />
                ))}
                {results?.map((member) => (
                  <CallPending
                    key={member.membership_id}
                    data={member}
                    
                  />
                ))}
                {!results.length && (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      No data
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {results.length > 0 && (
                <TableFooterWithPagination
                  count={meta.total}
                  rowsPerPage={meta.per_page}
                  page={meta.current_page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </Table>
          </div>
        </>)}
    </>
  );
}
