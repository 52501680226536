import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { Grid, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import FormField from "components/FormField";
import HeadingLine from "components/HeadingLine";
import { searchZipDataRequest } from "api/utilities";
import StatePicker from "components/StatePicker";
import CityPicker from "components/CityPicker";
import useMembership from "hooks/useMembership";
import useAccessControl from "hooks/useAccessControl";
import useStyles from "./styles";
import ".././../../../utils/sweetalert.css"

const PropertyUpdate = ({ index, property, form, setForm, defaultLocation, onRemove }) => {
  const theme = useTheme();
  const classes = useStyles();
  const { canUpdateMember } = useAccessControl();
  const {
    updateMembershipProperty,
    createMembershipProperty,
  } = useMembership();

  const PropertiesListValidationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    address: Yup.string().required("This field is required"),
    city: Yup.object().required("This field is required").nullable(),
    state: Yup.string().required(`This field is required`),
    zip: Yup.string().required(`This field is required`),
  });

  const setProperties = (data) => {
    const properties = [...form.properties];
    properties[index] = data;
    setForm({ properties });
  };

  const formik = useFormik({
    initialValues: {
      id: property.id || null,
      membership_id: form.membership.id,
      address: property.address,
      city: property.city,
      state: property.state,
      zip: property.zip,
    },
    validateOnChange: false,
    validationSchema: PropertiesListValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.id) {
        await updateMembershipProperty(values).then(({ data }) => {
          setProperties(data);
          Swal.fire({
            title: "Success",
            text: "Property Updated Successfully!",
            customClass: {
              title: "swal-text",
              popup:"swal-text"
              
            },
            confirmButtonColor: theme.palette.primary.main,
          });
        });
      } else {
        await createMembershipProperty(values).then(({ data }) => {
          setProperties(data);
          Swal.fire({
            title: "Success",
            text: "Property Created Successfully!",
            customClass: {
              title: "swal-text",
              popup:"swal-text"
              
            },
            confirmButtonColor: theme.palette.primary.main,
          });
        });
      }
    },
  });

  const handleChange = (e) => {
    formik.setFieldValue(e.target.name, e.target.value)
  };

  const setDefaults = () => {
    formik.setValues({
      city: {
        id: defaultLocation.city.id,
        label: defaultLocation.city.city,
      },
      state: defaultLocation.state.code,
      zip: defaultLocation.zip
    });
  };

  useEffect(() => {
    if (!formik.values.id) setDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    formik.setValues({
      ...formik.values,
      membership_id: form.membership.id || undefined,
      id: property.id || null,
      address: property.address,
      city: property.city,
      state: property.state,
      zip: property.zip,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property]);

  const handleZipChange = (e) => {
    searchZipDataRequest(e.target.value)
      .then(({ data }) => {
        formik.setValues({
          ...formik.values,
          state: data.details.state.code,
          zip: data.details.zip,
          city: {
            id: data.details.city.id,
            label: data.details.city.city,
          }
        }).then(() => {
          formik.setErrors({ ...formik.errors, zip: undefined});
        })
      })
      .catch((error) => {
        formik.setValues({
          ...formik.values,
          state: '',
          city: null
        }).then(() => {
          if (error?.response?.data?.errors) {
            Object.keys(error.response.data.errors).forEach((field) => {
              if (error.response.data.errors[field][0] !== undefined)
                formik.setFieldError(field, error.response.data.errors[field][0]);
            })
          }
        })
      });
  };

  return (
    <>
      {index === 0 && (
        <Grid item md={12} sm={12} xs={12}>
          <HeadingLine title="Property Details" />
        </Grid>
      )}
      <Grid item md={canUpdateMember() ? 4 : 5} sm={12} xs={12}>
        <FormField
          label={`Property ${index + 1} Address`}
          value={formik.values.address}
          name="address"
          onChange={handleChange}
          required
          error={Boolean(formik.errors.address)}
          helperText={formik.errors.address}
        />
      </Grid>
      <Grid item md={2} sm={12} xs={12}>
        <StatePicker
          name="state"
          label="State"
          value={formik.values.state}
          onChange={handleChange}
          InputLabelProps={{ shrink: true }}
          required
          error={Boolean(formik.errors.state)}
          helperText={formik.errors.state}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <CityPicker
          label="City"
          required
          InputLabelProps={{ shrink: true }}
          value={formik.values.city}
          name="city"
          state_code={formik.values.state}
          onChange={(val) => formik.setFieldValue('city', val)}
          error={Boolean(formik.errors.city)}
          helperText={formik.errors.city}
        />
      </Grid>
      <Grid item md={2} sm={12} xs={12}>
        <FormField
          InputLabelProps={{ shrink: true }}
          label="Zip Code"
          value={formik.values.zip}
          style={{
            "& input": {
              backgroundColor: "red",
            },
          }}
          name="zip"
          error={Boolean(formik.errors.zip)}
          helperText={formik.errors.zip}
          onChange={handleChange}
          onBlur={handleZipChange}
          required
        />
      </Grid>
      {canUpdateMember() && (
      <Grid item md={1} xs={12}>
        
          <LoadingButton
            loading={formik.isSubmitting}
            loadingPosition="center"
            variant="contained"
            type="submit"
            size="small"
            sx={{height: "40px"}}
            disabled={!formik.dirty}
            onClick={formik.submitForm}
          >
            {formik.values.id ? "Update" : 'Add'}
          </LoadingButton>
           
              {index !== 0 && (
                <Tooltip title="Delete" onClick={onRemove} >
                  <DeleteIcon color="error" className={classes.deleteIcon}/>
                </Tooltip>
              )}
           
        
      </Grid>
      )}
    </>
  );
};

export default PropertyUpdate;
