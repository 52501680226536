import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button, Chip, CircularProgress } from "@mui/material";
import { membershipColors } from "utils/membership";
import useAccessControl from "hooks/useAccessControl";
import MemberDialog from "./MemberDialog";

const MemberRow = ({ data, loading, deleteMemeberHandler }) => {
  const navigate = useNavigate();
  const { canUpdateMember } = useAccessControl();

  const [state, _setState] = React.useState({
    open: false,
  });
  const setState = (newState) => _setState({ ...state, ...newState });
  
  // const onDelete = () => {
  //   Swal.fire({
  //     title: "Delete member?",
  //     text: "You won't be able to revert this!",
  //     icon: "warning",
  //     customClass: {
  //       title: "swal-text",
  //       popup:"swal-text"
        
  //     },
  //     fontFamily: "Open Sans",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //   }).then((resp) => {
  //     DeletedMemberRequestv()
  //     Swal.fire({
  //       toast: true,
  //       icon: "success",
  //       text: "Member Deleted Successfully!",
  //       timer: 4000,
  //       customClass: {
  //         title: "swal-text",
  //         popup: "swal-text"

  //       },
  //       position: "top-end",
  //       timerProgressBar: true,
  //       showConfirmButton: false
  //     });
  //     navigate('/app/members')

  //   }).catch((error) => {
  //     Swal.fire({
  //       icon: "error",
  //       timer: 3000,
  //       position: 'top-right',
  //       text: error?.response?.data?.message || 'Something went wrong while updating user! Try again',
  //       customClass: {
  //         title: "swal-text",
  //         popup: "swal-text"
  //       }
  //     });
  //   });
  // };

  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <TableRow key={data.uuid}>
          <TableCell component="th" scope="row">
            {data.id}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.address}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.cell_phone}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.email}
          </TableCell>
          <TableCell component="th" scope="row">
            <Chip
              sx={{
                background: membershipColors[data.status],
                color: "white",
              }}
              label={data.status}
            />
          </TableCell>
          <TableCell align="right" >
            <div style={{ display: 'flex', mt: 1.5 }}>
            {canUpdateMember() && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                sx={{ mr: 2.0 }}
                onClick={() => navigate(`/app/members/${data.membership_id}`)}
              >
                View
              </Button>
            )
            }
            <Button
              variant="contained"
              color="error"
              size="small"
              // onClick={() => setState({ open: true })}
              onClick={() => deleteMemeberHandler(data.id)}
            >
              Cancel
            </Button>
            </div>
          </TableCell>
          <MemberDialog
            serviceLocation={state.serviceLocation}
            data={data}
            open={state.open}
            onClose={() => setState({ open: false, serviceLocation: null })}
          />
        </TableRow>
      )}
    </>
  );
};

export default MemberRow;
